:root {
  --ion-color-primarydk: #00274e;
  --ion-color-primary100: #257099;
  --ion-color-primary80: #518dad;
  --ion-color-primary60: #7ca9c2;
  --ion-color-primary40: #a8c6d6;
  --ion-color-primary20: #d3e2eb;
  --ion-color-secondarydk: #a03f1d;
  --ion-color-secondary100: #e96127;
  --ion-color-secondary80: #ed8152;
  --ion-color-secondary60: #f2a07d;
  --ion-color-secondary40: #f6c0a9;
  --ion-color-secondary20: #fbdfd4;
  --ion-color-gray100: #273138;
  --ion-color-gray80: #4b5961;
  --ion-color-gray60: #738289;
  --ion-color-gray40: #9daab0;
  --ion-color-gray20: #ccd4d8;
  --ion-color-gray05: #f4f4f5;
  --ion-color-gray03: #828282;
  --ion-color-gray01:#333333;
  --ion-color-black: #000000;
  --ion-color-white: #ffffff;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-highlight: #007fc5;
  --ion-color-highlightBG: #F2F9FC;
  --ion-color-success100: #4caf50;
  --ion-color-success60: #94cf96;
  --ion-color-success20: #dbefdc;
  --ion-color-warning100: #f9a825;
  --ion-color-warning60: #fbcb7c;
  --ion-color-warning20: #feeed3;
  --ion-color-error100: #d32f2f;
  --ion-color-error60: #e58282;
  --ion-color-error20: #f6d5d5;
  --ion-color-info100: #2685ca;
  --ion-color-info60: #7db6df;
  --ion-color-info20: #D4E7F4;
  --ion-color-attention: #92278f;
  --note-bg: #FFFFE0;
  --ion-color-alert-success100:#4CAF50;
  --ion-color-alert-Warning100:#F9A825;
  --ion-color-alert-SecondaryDk:#A03F1D;
  --ion-color-alert-Primary100: #257099;
  --ion-color-warningText: #BE5815;

  //for risk
  --ion-color-highrisk:#C70039;
  --ion-color-veryhighrisk:#900C3F;

  //for Rxmeds
  --ion-color-lavender:#C16BFF;
  --ion-color-violet:#5935D1;
  --ion-color-fav:#FFD700;

  --ion-color-appt-green: #32CC7C;
  --ion-color-appt-light-green: #ccf2de;
  --ion-color-appt-dark-blue: #2457AD;
  --ion-color-appt-light-yellow: #EAEA85;
  --ion-color-appt-orange: #FFB836;
  --ion-color-appt-lavender: #C16BFF;
  --ion-color-appt-red: #DB184C;
  --ion-color-gray-bg: #E5E5E5;

  --ion-color-acknowledge: #efdaff;

  --ion-color-teal: #C9F5F9;
  --ion-color-yellow: #FFFEE0;
  --ion-color-dark-teal:#27D9E6;
  --ion-color-orange-bg: #FFEDCD;
  
  --ion-color-new-yellow: #FFF9C4;
  --ion-color-light-pink: #FFEDFF;
}

.ion-color-primarydk {
  --ion-color-base: var(--ion-color-primarydk);
}

.ion-color-primary100 {
  --ion-color-base: var(--ion-color-primary100);
}

.ion-color-primary80 {
  --ion-color-base: var(--ion-color-primary80);
}

.ion-color-primary60 {
  --ion-color-base: var(--ion-color-primary60);
}

.ion-color-primary40 {
  --ion-color-base: var(--ion-color-primary40);
}

.ion-color-primary20 {
  --ion-color-base: var(--ion-color-primary20);
}

.ion-color-secondarydk {
  --ion-color-base: var(--ion-color-secondarydk);
}

.ion-color-secondary100 {
  --ion-color-base: var(--ion-color-secondary100);
}

.ion-color-secondary80 {
  --ion-color-base: var(--ion-color-secondary80);
}

.ion-color-secondary60 {
  --ion-color-base: var(--ion-color-secondary60);
}

.ion-color-secondary40 {
  --ion-color-base: var(--ion-color-secondary40);
}

.ion-color-secondary20 {
  --ion-color-base: var(--ion-color-secondary20);
}

.ion-color-gray100 {
  --ion-color-base: var(--ion-color-gray100)!important;
}

.ion-color-gray80 {
  --ion-color-base: var(--ion-color-gray80)!important;
}

.ion-color-gray60 {
  --ion-color-base: var(--ion-color-gray60)!important;
}

.ion-color-gray40 {
  --ion-color-base: var(--ion-color-gray40);
}

.ion-color-gray20 {
  --ion-color-base: var(--ion-color-gray20);
}

.ion-color-gray05 {
  --ion-color-base: var(--ion-color-gray05);
}

.ion-color-gray03 {
  --ion-color-base: var(--ion-color-gray03);
}

.ion-color-gray01 {
  --ion-color-base: var(--ion-color-gray01);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-shade: var(--ion-color-white-shade);
}

.ion-color-highlight {
  --ion-color-base: var(--ion-color-highlight);
}

.ion-color-success100 {
  --ion-color-base: var(--ion-color-success100);
}

.ion-color-success60 {
  --ion-color-base: var(--ion-color-success60);
}

.ion-color-success20 {
  --ion-color-base: var(--ion-color-success20);
}

.ion-color-warning100 {
  --ion-color-base: var(--ion-color-warning100);
}

.ion-color-warning60 {
  --ion-color-base: var(--ion-color-warning60);
}

.ion-color-warning20 {
  --ion-color-base: var(--ion-color-warning20);
}

.ion-color-error100 {
  --ion-color-base: var(--ion-color-error100)!important;
}

.ion-color-error60 {
  --ion-color-base: var(--ion-color-error60);
}

.ion-color-error20 {
  --ion-color-base: var(--ion-color-error20);
}

.ion-color-info100 {
  --ion-color-base: var(--ion-color-info100);
}

.ion-color-info60 {
  --ion-color-base: var(--ion-color-info60);
}

.ion-color-info20 {
  --ion-color-base: var(--ion-color-info20);
}

.ion-color-attention {
  --ion-color-base: var(--ion-color-attention);
}
.note-bg{
  --ion-color-base: var(--note-bg);
}

.ion-color-violet{
  --ion-color-base: var(--ion-color-violet);
}

.ion-color-warningText{
  --ion-color-base: var(--ion-color-warningText);
}

.ion-color-darkBlue {
  --ion-color-base: var(--ion-color-appt-dark-blue);
}

.ion-color-yellow {
  --ion-color-base: #FFFD84;
}

.ion-color-darkRed {
  --ion-color-base: var(--ion-color-appt-red);
}

//for risk
.ion-color-highrisk{
  --ion-color-base:var(--ion-color-highrisk);
}
.ion-color-veryhighrisk{
  --ion-color-base:var(--ion-color-veryhighrisk);
}
// Gradient color

//for RX-Med
.ion-color-lavender{
  --ion-color-base:var(--ion-color-lavender);
}
.ion-color-violet{
  --ion-color-base:var(--ion-color-violet) !important;
}
.ion-color-fav{
  --ion-color-base:var(--ion-color-fav)
}
.ion-color-acknowledge{
  --ion-color-base:var(--ion-color-acknowledge);
}
.ion-color-orange{
  --ion-color-base: var(--ion-color-appt-orange);
}

.ion-color-apptRed{
  --ion-color-base: var(--ion-color-appt-red);
}

.highlight-bg{
  --ion-color-base: var(--ion-color-highlightBG);
}

.ion-color-apptGreen{
  --ion-base-color: var(--ion-color-appt-green);
}

:root {
  --health-object-gradient-angle: 130deg;
  --health-object-primary-grad: linear-gradient(
    var(--health-object-gradient-angle),
    #257099,
    #518dad
  );
  --health-object-secondary-grad: linear-gradient(
    var(--health-object-gradient-angle),
    #e96127,
    #ed8152
  );
  --health-object-gray-grad: linear-gradient(
    var(--health-object-gradient-angle),
    #273138,
    #4b5961
  );
  --health-object-login-grad: linear-gradient(
    var(--health-object-gradient-angle),
    #fdefe9,
    #c9dbe6
  );
  --health-object-gradient-ruby: linear-gradient(
    var(--health-object-gradient-angle),
    #be1e2d,
    #f15a29
  );
  --health-object-gradient-mandarian: linear-gradient(
    var(--health-object-gradient-angle),
    #f15a29,
    #e98a41
  );
  --health-object-gradient-grass: linear-gradient(
    var(--health-object-gradient-angle),
    #4c9c91,
    #50b540
  );
  --health-object-gradient-sea: linear-gradient(
    var(--health-object-gradient-angle),
    #4f55a2,
    #1261b5
  );
  --health-object-gradient-diagnosis: linear-gradient(
    var(--health-object-gradient-angle),
    #2b3990,
    #92278f
  );
  --health-object-gradient-neon: linear-gradient(
    var(--health-object-gradient-angle),
    #92278f,
    #ed1c24
  );
}

.item-text-row-secondary,
.item-text-row-primary {
  ion-text[color="secondarydk"] {
    color: var(--ion-color-secondarydk);
  }

  ion-text[color="primary100"] {
    color: var(--ion-color-primary100);
  }

  ion-text[color="attention"] {
    color: var(--ion-color-attention);
  }

}

.bg-white{
  background-color: var(--white);
}

.purple-gradient-bg{
  background: linear-gradient(116.48deg, #2B3990 23.22%, #92278F 73.3%);
}


//Medications status colors
.med_not_send_bg{
  background-color: #DBEAFF;
}
.med_pending_bg{
  background-color: #FFEDCD;
}
.med_accepted_bg{
  background-color: #CCF2DE;
}
.med_partially_bg{
  background-color: #CCF2DE;
  border:0.1rem solid #32CC7C;
}
.med_filled_bg{
  background-color: #32CC7C;
}
.med_notfilled_bg{
  background-color: #F6C5D2;
}
.med_rejected_bg{
  background-color: #DB184C;
}
.med_transfer_bg{
  background-color:  #C9F5F9;
}
.med_cancel_rx{
  background-color: #FEEED3;
}
.med_cancel_denied{
  background-color: #F9A825;
}
.med_rx_canceled{
  background-color: #D32F2F;
}
.med_rx_canceled_badge{
  background-color: #DB184C;
}

.teal_bg{
  background-color: #C9F5F9;
}

.green_bg{
  background-color: #CCF2DE;
}

.green_chip{
  background-color: var(--ion-color-appt-green)
}
.yellow_chip{
  background-color: #FFFD84;
}
.darkBlue-chip{
  background-color: var(--ion-color-appt-dark-blue);
}
.red-chip{
  background-color: var(--ion-color-appt-red);
}
.gray-chip{
  background-color: #999999;
}
.lightGray-chip{
  background-color: var(--ion-color-gray-bg);
}
.teal-chip{
  background-color: var(--ion-color-teal);
}
.amended-chip{
  background-color: var(--ion-color-new-yellow);
}
.brown-chip{
  background-color: var(--medical-category--brown);
}
.pink-chip{
  background-color: var(--ion-color-light-pink);
}
.dropdown{
  position: relative;
  .dropdown-menu{
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 19.4rem;
      text-align: left;
      list-style: none;
      background-color: var(--white);
      background-clip: padding-box;
      box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.15);
      margin: 0;
      padding: 0;

      .dropdown-item {
        display: block;
        width: 100%;
        padding: 1.2rem 2rem 1.2rem 1rem;
        clear: both;
        font-weight: 400;
        color: #58606E;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        text-decoration: none;
        font-size: 1.6rem;
        letter-spacing: 0.005em;
        line-height: 1.9rem;
        cursor: pointer;
    }

    .dropdown-divider {
      height: 0;
      overflow: hidden;
      border-top: 0.1rem solid var(--ion-color-gray20);
    }
    &.ShowDropdown{
      display: block;
    }
  }

}

.more_dropdown{
    background-color: #fff;
    position: absolute;
    width: max-content;
    right: 0;
    padding: 1rem;
    border-radius: 0.4rem;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.15);
    z-index: 9;
}
.chat {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  > * {
    margin-bottom: 2rem;
  }

}

.chat-item {
    max-width: 75%;
    position: relative;
    border-radius: var(--q-app--border-radius);
    word-break: break-word!important;
    overflow-wrap: break-word!important;
    &,
    &.is-received {
        background-color: #FFF;
        width: fit-content;
        border-bottom-left-radius: 0px;
    }
    &.is-sent {
      background-color:  var(--ion-color-primary);
      color: var(--ion-color-primary-contrast); //var(--interaction--darker)
      margin-left: auto;
      width: fit-content;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: var(--q-app--border-radius);
    }

}

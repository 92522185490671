
.chartSliderHeader {
  padding: 1.6rem 0 0 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 4.5rem;

  @media (max-width: 767px) {
    align-items: self-start;
  }

  @media (max-width: 575px) {
    align-items: center;
  }

  &-main {
    display: flex;
    cursor: pointer;
    align-items: center;
    .chartSliderHeader-arrow {
      margin-right: 1rem;
    }
    .chartSliderHeader-title {
      display: flex;
      align-items: center;
      min-width: 20rem;
      &-icon {
        font-size: 2.4rem;
        flex-shrink: 0;
        margin-right: .6rem;
      }
    }
  }

  &-middle {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;

    @media (max-width: 767px) {
      margin: 0.5rem 0.5rem 0.5rem 3.2rem;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1.5rem;
    gap: 1.5rem;
    ion-button {
      border: 0.1rem solid #9DAAB0;
      margin: 0;
      height: 2.6rem;
      min-height: 2.4rem;
      padding: 0;
      ion-icon {
        font-size: 1.6rem;
        padding: 0rem 0.5rem;
      }
    }
    .next-btn {
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0;
    }

    .prev-btn {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      padding: 0;
    }
  }
  .row {
    width: auto !important;
  }
}

.chartSlider {
  display: flex;
  overflow: auto;
  padding: 1rem 0 1rem 1.8rem;
  gap: 1rem;
  align-items: start;
  scroll-padding-left: 2.5rem;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
  scroll-snap-type: both mandatory;
  overscroll-behavior-x: contain;
  &:empty {
    padding-top: 0;
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
      display: none;
  }
  &-item {
    flex-shrink: 0;
    width: 24rem;
    margin: 0rem;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    box-shadow: none;
    ion-card-content {
      padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    }

    &:last-child {
      margin-right: 1.5rem
    }
  }

}

.chartSlider.chartSlider-hidden {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
}

.chartSlider.chartSlider-wrapItems {
  flex-wrap: wrap;
  padding-right: 1.5rem;
  .chartSlider-item {
    align-self: stretch;
  }
}

@media screen and (max-width: 767px) {
  .chartSlider.chartSlider-expanded {
    .chartSlider-item {
      width: 100%
    }
  }
}

@media screen and (max-width: 525px) {
   // On narrow screens, make the cards full width
  .chartSlider.chartSlider-wrapItems {
    .chartSlider-item {
      width: 100%
    }
  }
}

// New SCSS added

.sliderHeader {
  padding: 1.6rem 0 0 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 4.5rem;

  @media (max-width: 767px) {
    align-items: self-start;
  }

  @media (max-width: 575px) {
    align-items: center;
  }

  &-main {
    display: flex;
    cursor: pointer;
    align-items: center;
    .sliderHeader-arrow {
      margin-right: 1rem;
    }
    .sliderHeader-title {
      display: flex;
      align-items: center;
      min-width: 20rem;
      &-icon {
        font-size: 2.4rem;
        flex-shrink: 0;
        margin-right: .6rem;
      }
    }
  }

  &-middle {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;

    @media (max-width: 767px) {
      margin: 0.5rem 0.5rem 0.5rem 3.2rem
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1.5rem;
    gap: 1.5rem;
    ion-button {
      border: 0.1rem solid #9DAAB0;
      margin: 0;
      height: 2.6rem;
      min-height: 2.4rem;
      padding: 0;
      ion-icon {
        font-size: 1.6rem;
        padding: 0rem 0.5rem;
      }
    }
    .next-btn {
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0;
    }

    .prev-btn {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      padding: 0;
    }
  }
  .row {
    width: auto !important;
  }
}

.slider {
  display: flex;
  overflow: auto;
  padding: 1rem 0px 1rem 1.8rem;
  gap: 1rem;
  align-items: start;
  scroll-padding-left: 2.5rem;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
  scroll-snap-type: both mandatory;
  overscroll-behavior-x: contain;
  &:empty {
    padding-top: 0;
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
      display: none;
  }
  &-item {
    flex-shrink: 0;
    width: 24rem;
    margin: 0;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    box-shadow: none;
    ion-card-content {
      padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    }

    &:last-child {
      margin-right: 1.5rem
    }
  }

}

.slider-hidden {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
}

.slider.slider-hidden {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
}

.slider.slider-wrapItems {
  flex-wrap: wrap;
  padding-right: 1.5rem;
  .slider-item {
    align-self: stretch;
  }
}

@media screen and (max-width: 767px) {
  .slider.slider-expanded {
    .slider-item {
      width: 100%
    }
  }
}

@media screen and (max-width: 525px) {
   // On narrow screens, make the cards full width
  .slider.slider-wrapItems {
    .slider-item {
      width: 100%
    }
  }
}

.closed-card{
  background-color: var(--ion-color-gray05);
}
html {
  font-size: 62.5%;
}

:root {
  --queue-patient--header: 140px;

  @media (max-width:767px) {
    --queue-patient--header: 190px;
  }

  @media (max-width:575px) {
    --queue-patient--header: 135px;
  }
}

.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-opacity {
  &--0 {
    opacity: 0;
  }

  &--25 {
    opacity: 0.25;
  }

  &--50 {
    opacity: 0.5;
  }

  &--75 {
    opacity: 0.75;
  }

  &--100 {
    opacity: 1;
  }
}

.u-scroll {

  &,
  &--xy {
    overflow: scroll;
  }

  &--x {
    overflow-x: scroll;
  }

  &--y {
    overflow-y: scroll;
  }
}

.u-border-radius {
  --border-radius: var(--q-app--border-radius);
  border-radius: var(--q-app--border-radius);
}

.u-invisible {
  visibility: hidden !important;
}



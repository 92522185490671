
@mixin checkMixinForValidArguments($input, $expected) {
  @if (index($expected, $input) == null) {
    @warn "'#{$input}' is not a valid argument... We expected one of the following '#{$expected}' ";
  }
}


@mixin header($h--1-through-6) {
  @include checkMixinForValidArguments($h--1-through-6, 1 2 3 4 5 6);
  font-size: var(--q-font-size--header);
  font-weight: var(--q-font-weight--header);
  letter-spacing: var(--q-letter-spacing--header);
  @if $h--1-through-6 == 1 {
    --q-font-size--header: var(--q-font-size--h1);
    --q-font-weight--header: light;
  } @else if $h--1-through-6 == 2 {
    --q-font-size--header: var(--q-font-size--h2);
    --q-font-weight--header: bold;
  } @else if $h--1-through-6 == 3 {
    --q-font-size--header: var(--q-font-size--h3);
    --q-font-weight--header: normal;
  } @else if $h--1-through-6 == 4 {
    --q-font-size--header: var(--q-font-size--h4);
    --q-font-weight--header: bold;
  } @else if $h--1-through-6 == 5 {
    --q-font-size--header: var(--q-font-size--h5);
    --q-font-weight--header: bold;
    text-transform: uppercase;
  } @else if $h--1-through-6 == 6 {
    --q-font-size--header: var(--q-font-size--h6);
    --q-font-weight--header: regular;
    text-transform: uppercase;
  }
}

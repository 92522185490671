.medicalCategories {
  @mixin categories($border-color) {
    padding: 0.5rem 1rem 0.3rem;
    border-radius: 5rem;
    display: inline-block;
    border: 0.3rem solid ($border-color);
    background-color: var(--ion-color-white);
  }

  .medicalCat-1 ion-text {
    @include categories(var(--medical-category--red));
  }
  .medicalCat-1-light ion-text {
    @include categories(var(--ion-color-error60));
  }

  .medicalCat-2 ion-text {
    @include categories(var(--medical-category--orange));
  }

  .medicalCat-3 ion-text {
    @include categories(var(--medical-category--dark-blue));
  }

  .medicalCat-4 ion-text {
    @include categories(var(--medical-category--teal));
  }

  .medicalCat-yellow ion-text {
    @include categories(var(--ion-color-warning100));
  }

  @media (max-width: 867px) {
    div {
      display: inline-block;
      // margin-right: 10px;
    }
  }
}


//Appointment box scss

@mixin box($bg) {
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  background-color: var($bg);
}

.box-success{
  @include box(--ion-color-success100)
}

.box-alert{
  @include box(--ion-color-error100)
}

.box-warning{
  @include box(--ion-color-warning100)
}

.w-0 {
    width: 0;
  }
  
  .w-10 {
    width: 10%;
  }
  
  .w-15 {
    width: 15%;
  }
  
  .w-18{
    width: 18%;
  }
  
  .w-20 {
    width: 20%;
  }
  
  .w-21 {
    width: 21%;
  }
  
  .w-22 {
    width: 22%;
  }
  .w-25 {
    width: 25%;
  }
  
  .w-30 {
    width: 30%;
  }
  
  .w-33 {
    width: 33%;
  }
  
  .w-35 {
    width: 35%;
  }
  
  .w-40 {
    width: 40%;
  }
  
  .w-45 {
    width: 45%;
  }
  
  .w-55 {
    width: 55%;
  }
  
  .w-50 {
    width: 50%;
  }
  
  .w-60 {
    width: 60%;
  }
  
  .w-65 {
    width: 65%;
  }
  
  .w-70 {
    width: 70%;
  }
  
  .w-75 {
    width: 75%;
  }
  
  .w-80 {
    width: 80%;
  }
  .w-85 {
    width: 85%;
  }
  .w-83{
    width: 83%;
  }
  .w-90 {
    width: 90%;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .h-100 {
    height: 100%;
  }
  
  .max-w-27 {
    max-width: 27%;
  }
  
  .max-w-40 {
    max-width: 40%;
  }
  
  .max-w-50 {
    max-width: 50%;
  }
  
  .max-w-100 {
    max-width: 100%;
  }
  
  .max-h-50 {
    max-height: 50%;
  }
  
  .max-h-100 {
    max-height: 100%;
  }
  
  // margin classes
  
  .m-0 {
    margin: 0;
  }
  
  .m-1 {
    margin: 0.1rem;
  }
  
  .m-2 {
    margin: 0.2rem;
  }
  
  .m-3 {
    margin: 0.3rem;
  }
  
  .m-4 {
    margin: 0.4rem;
  }
  
  .m-5 {
    margin: 0.5rem;
  }
  
  .m-10 {
    margin: 1rem;
  }
  
  .m-15 {
    margin: 1.5rem;
  }
  
  .m-20 {
    margin: 2rem;
  }
  
  // margin x-axis
  .mx-0{
    margin-left: 0rem;
    margin-right: 0rem;
  }
  
  .mx-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .mx-6 {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  
  .mx-10 {
    margin-left: 1rem;
    margin-right: 1rem;
  
  }
  
  .mx-15 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  
  .mx-20 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .mx-25 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  
  .mx-30 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  
  // margin y-axis
  
  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .my-4 {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  
  .my-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .my-8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  
  .my-10 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  
  .my-15 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  // margin top scss
  
  .mt-0 {
    margin-top: 0!important;
  }
  
  .mt-1 {
    margin-top: 0.1rem;
  }
  
  .mt-2 {
    margin-top: 0.2rem;
  }
  
  .mt-3 {
    margin-top: 0.3rem;
  }
  
  .mt-4 {
    margin-top: 0.4rem;
  }
  
  .mt-5 {
    margin-top: 0.5rem;
  }
  
  .mt-8 {
    margin-top: 0.8rem;
  }
  
  .mt-7 {
    margin-top: 0.7rem;
  }
  
  .mt-10 {
    margin-top: 1rem;
  }
  
  .mt-12 {
    margin-top: 1.2rem;
  }
  
  .mt-15 {
    margin-top: 1.5rem;
  }
  
  .mt-20 {
    margin-top: 2rem;
  }
  
  .mt-25 {
    margin-top: 2.5rem;
  }
  
  .mt-30 {
    margin-top: 3rem;
  }
  
  .mt-35{
    margin-top: 3.5rem;
  }
  
  .mt-40 {
    margin-top: 4rem;
  }
  
.mt-50 {
    margin-top: 5rem;
  }
  
  .mt-70 {
    margin-top: 7rem;
  }
  
  .mt-100 {
    margin-top: 10rem;
  }
  
  
  
  // margin bottom scss
  
  .mb-0 {
    margin-bottom: 0!important;
  }
  .mb-1 {
    margin-bottom: 0.1rem;
  }
  .mb-2 {
    margin-bottom: 0.2rem;
  }
  
  .mb-3 {
    margin-bottom: 0.3rem;
  }
  
  .mb-4 {
    margin-bottom: 0.4rem;
  }
  
  .mb-5 {
    margin-bottom: 0.5rem;
  }
  
  .mb-7 {
    margin-bottom: 0.7rem;
  }
  .mb-8 {
    margin-bottom: 0.8rem;
  }
  
  .mb-9 {
    margin-bottom: 0.9rem;
  }
  
  .mb-10 {
    margin-bottom: 1rem;
  }
  
  .mb-12 {
    margin-bottom: 1.2rem;
  }
  
  .mb-13 {
    margin-bottom: 1.3rem;
  }
  
  
  .mb-15 {
    margin-bottom: 1.5rem;
  }
  
  .mb-18 {
    margin-bottom: 1.8rem;
  }
  
  .mb-20 {
    margin-bottom: 2rem;
  }
  
  .mb-25 {
    margin-bottom: 2.5rem;
  }
  
  .mb-30 {
    margin-bottom: 3rem;
  }
  
  .mb-30-new{
    margin-bottom: 3rem;
  }
  
  .mb-35 {
    margin-bottom: 3.5rem;
  }
  
  .mb-40 {
    margin-bottom: 4rem;
  }
  
  .mb-50 {
    margin-bottom: 5rem;
  }
  
  // margin left scss
  
  .ml-0 {
    margin-left: 0;
  }
  
  .ml-2 {
    margin-right: 0.2rem;
  }
  
  .ml-3 {
    margin-left: 0.3rem;
  }
  
  
  .ml-5 {
    margin-left: 0.5rem;
  }
  
  .ml-8 {
    margin-left: 0.8rem;
  }
  
  .ml-10 {
    margin-left: 1rem;
  }
  
  .ml-12 {
    margin-left: 1.2rem;
  }
  
  .ml-15 {
    margin-left: 1.5rem;
  }

  .ml-18{
    margin-left:1.8rem;
  }
  
  .ml-20 {
    margin-left: 2rem;
  }
  
  .ml-25 {
    margin-left: 2.5rem;
  }
  
  .ml-30 {
    margin-left: 3rem;
  }
  
  .ml-40 {
    margin-left: 4rem;
  }
  
  
  
  // margin right scss
  
  .mr-0 {
    margin-right: 0;
  }
  
  .mr-1 {
    margin-right: 0.1rem;
  }
  
  .mr-2 {
    margin-right: 0.2rem;
  }
  
  .mr-3 {
    margin-right: 0.3rem;
  }
  
  .mr-4{
    margin-right: 0.4rem;
  }
  
  .mr-5 {
    margin-right: 0.5rem;
  }

  .mr-7 {
    margin-right: 0.7rem;
  }
  
  .mr-8 {
    margin-right: 0.8rem;
  }
  
  .mr-10 {
    margin-right: 1rem;
  }
  
  .mr-13 {
    margin-right: 1.3rem;
  }
  
  .mr-15 {
    margin-right: 1.5rem;
  }
  
  .mr-20 {
    margin-right: 2rem;
  }
  
  .mr-22 {
    margin-right: 2.2rem;
  }
  
  .mr-25 {
    margin-right: 2.5rem;
  }
  
  .mr-29 {
    margin-right: 2.9rem;
  }
  
  .mr-30 {
    margin-right: 3rem;
  }
  
  // padding scss
  
  .p-0 {
    padding: 0;
  }
  
  .p-1 {
    padding: 0.1rem;
  }
  
  .p-2 {
    padding: 0.2rem;
  }
  
  .p-3 {
    padding: 0.3rem;
  }
  
  .p-4 {
    padding: 0.4rem;
  }
  
  .p-5 {
    padding: 0.5rem;
  }
  
  .p-6 {
    padding: 0.6rem;
  }
  
  .p-10 {
    padding: 1rem;
  }
  
  .p-12 {
    padding: 1.2rem;
  }
  
  .p-15 {
    padding: 1.5rem;
  }
  
  .p-20 {
    padding: 2rem;
  }
  
  .p-25 {
    padding: 2.5rem;
  }
  
  .p-30 {
    padding: 3rem;
  }
  
  .p-50 {
    padding: 5rem;
  }
  
  // padding x-axis
  
  .px-0{
    padding-left: 0rem;
    padding-right: 0rem;
  
  }
  .px-2{
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  
  .px-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  
  .px-10 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .px-15 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  
  .px-20 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .px-25 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  
  .px-30 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  .px-50 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  
  .px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem;
  }
  
  
  // padding y-axis
  .py-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
  
  .py-2 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .py-3 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .py-4 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  
  .py-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .py-8 {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  
  .py-10 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  
  .py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .py-50 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  // padding top scss
  
  .pt-0 {
    padding-top: 0;
  }
  
  .pt-1 {
    padding-top: 0.1rem;
  }
  
  .pt-3 {
    padding-top: 0.3rem;
  }
  .pt-4 {
    padding-top: 0.4rem;
  }
  
  .pt-5 {
    padding-top: 0.5rem;
  }
  
  .pt-6 {
    padding-top: 0.6rem;
  }
  
  .pt-7 {
    padding-top: 0.7rem;
  }
  
  .pt-8 {
    padding-top: 0.8rem;
  }
  
  .pt-9 {
    padding-top: 0.9rem;
  }
  
  .pt-10 {
    padding-top: 1rem;
  }
  
  .pt-11 {
    padding-top: 1.1rem;
  }
  
  .pt-12 {
    padding-top: 1.2rem;
  }
  
  .pt-15 {
    padding-top: 1.5rem;
  }
  
  .pt-16 {
    padding-top: 1.6rem;
  }
  
  .pt-20{
  padding-top: 2rem;
  }
  .pt-30{
    padding-top: 3rem;
  }
  
  .pt-50 {
    padding-top: 5rem;
  }
  
  .pt-100 {
    padding-top: 10rem;
  }
  
  // padding bottom scss
  
  .pb-0 {
    padding-bottom: 0;
  }
  
  .pb-3 {
    padding-bottom: 0.3rem;
  }
  
  .pb-5 {
    padding-bottom: 0.5rem;
  }
  
  .pb-8 {
    padding-bottom: 0.8rem;
  }
  
  .pb-9 {
    padding-bottom: 0.9rem;
  }
  
  .pb-10 {
    padding-bottom: 1rem;
  }
  
  .pb-11 {
    padding-bottom: 1.1rem;
  }
  
  .pb-12 {
    padding-bottom: 1.2rem;
  }
  
  .pb-13 {
    padding-bottom: 1.3rem;
  }
  
  .pb-14 {
    padding-bottom: 1.4rem;
  }
  
  .pb-15 {
    padding-bottom: 1.5rem;
  }
  
  .pb-16 {
    padding-bottom: 1.6rem;
  }
  
  .pb-18 {
    padding-bottom: 1.8rem;
  }
  
  .pb-20 {
    padding-bottom: 2rem;
  }
  
  .pb-30 {
    padding-bottom: 3rem;
  }
  
  .pb-40 {
    padding-bottom: 4rem;
  }
  
  .pb-50 {
    padding-bottom: 5rem;
  }
  
  // padding left scss
  
  .pl-0 {
    padding-left: 0;
  }
  
  .pl-3 {
    padding-left: 0.3rem;
  }
  
  .pl-5 {
    padding-left: 0.5rem;
  }
  
  .pl-8 {
    padding-left: 0.8rem;
  }
  
  .pl-10 {
    padding-left: 1rem;
  }
  
  .pl-12 {
    padding-left: 1.2rem;
  }
  
  .pl-15 {
    padding-left: 1.5rem;
  }
  
  .pl-16 {
    padding-left: 1.6rem;
  }
  
  .pl-17 {
    padding-left: 1.7rem;
  }
  
  .pl-20 {
    padding-left: 2rem;
  }
  
  .pl-30 {
    padding-left: 3rem;
  }
  
  .pl-32 {
    padding-left: 3.2rem;
  }
  .pl-45 {
    padding-left: 4.5rem;
  }
  // padding right scss
  
  .pr-0 {
    padding-right: 0;
  }
  
  .pr-3 {
    padding-right: 0.3rem;
  }
  
  .pr-5 {
    padding-right: 0.5rem;
  }
  
  .pr-6 {
    padding-right: 0.6rem;
  }
  
  .pr-8 {
    padding-right: 0.8rem;
  }
  
  .pr-10 {
    padding-right: 1rem;
  }
  
  .pr-12 {
    padding-right: 1.2rem;
  }
  
  .pr-15 {
    padding-right: 1.5rem;
  }
  
  
  .pr-16 {
    padding-right: 1.6rem;
  }
  
  .pr-17 {
    padding-right: 1.7rem;
  }
  
  .pr-20 {
    padding-right: 2rem;
  }
  
  .pr-30 {
    padding-right: 3rem;
  }
  
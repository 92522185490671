.popover {
  display: flex;
  flex-flow: column;

  &-header {
    flex-shrink: 0;
    border-bottom: var(--q-app--border);
    h3 { margin: 0 6px; }
  }
  &-body {
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    flex-shrink: 0;
  }

  &-header,
  &-footer {
    padding: 6px calc(var(--ion-safe-area-right, 0) + 16px) 6px calc(var(--ion-safe-area-left, 0) + 16px);
  }
}

.popover-md {
  --width: 34rem;
}

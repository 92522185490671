/* Ionic does not allow customization of breakpoints, and therefore are hardcoded. Here we assign these hard coded values to SCSS variables (not --custom-properties) so they can be imported for use within component-specific partials. To import in a component-specific partial use "@import 'breakpoints';" */

$breakpoint--xs: 0;

// min-width values (e.g. media (min-width: $var))
$breakpoint--xs--min: 481px;
$breakpoint--sm--min: 576px;
$breakpoint--md--min: 768px;
$breakpoint--lg--min: 992px;
$breakpoint--xl--min: 1200px;

// max-width values (e.g. media (max-width: $var))
$breakpoint--xs--max: 480.98px;
$breakpoint--sm--max: 575.98px;
$breakpoint--md--max: 767.98px;
$breakpoint--lg--max: 991.98px;
$breakpoint--xl--max: 1199.98px;

// Application Colors

// The application colors are used in multiple places in Ionic. These are useful for easily creating dark themes or themes that match a brand.
// It is important to note that the background and text color variables also require a rgb variable to be set in rgb format. See The Alpha Problem for an explanation of why the rgb property is also needed.

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// App wide
:root {
  --ion-background-color: var(--q-app-background-color);
  --ion-background-color-rgb: var(--q-app-background-color-rgb);
  --ion-text-color: var(--q-app-text-color);
  --ion-text-color--rgb: var(--q-app-text-color--rgb);

  --ion-item-background: transparent;
}

// Component or area specific

// Main app Toolbar
main-header {
  --ion-toolbar-background: var(--q-app-banner-background-color);
}

// App-wide Sidebar / main nav / mobile footer
main-nav {
  --ion-tab-bar-background: var(--q-app-sidebar-background-color);
}

// Cards
ion-card {
  --background: #FFF;
  border-radius: var(--q-app--border-radius);
  // box-shadow: 0px 4px 4px rgb(0 0 0 / 20%), 0px 0px 2px rgb(0 0 0 / 20%);
  border: 1px solid #CCD4D8;
}


/////////////////////////////////////////////////////////////////////////////
// AVAILABLE
// --ion-background-color ///	Background color of the entire app
// --ion-background-color-rgb ///	Background color of the entire app, rgb format
// --ion-text-color ///	Text color of the entire app
// --ion-text-color-rgb ///	Text color of the entire app, rgb format
// --ion-backdrop-color ///	Color of the Backdrop component
// --ion-backdrop-opacity	 ///Opacity of the Backdrop component
// --ion-overlay-background-color ///	Background color of the overlays
// --ion-border-color ///	Border color
// --ion-box-shadow-color ///	Box shadow color
// --ion-tab-bar-background	/// Background of the Tab Bar
// --ion-tab-bar-background-focused /// Background of the focused Tab Bar
// --ion-tab-bar-border-color ///	Border color of the Tab Bar
// --ion-tab-bar-color /// Color of the Tab Bar
// --ion-tab-bar-color-selected ///	Color of the selected Tab Button
// --ion-toolbar-background ///	Background of the Toolbar
// --ion-toolbar-border-color ///	Border color of the Toolbar
// --ion-toolbar-color ///	Color of the components in the Toolbar
// --ion-toolbar-segment-color ///	Color of the Segment Buttons in the Toolbar
// --ion-toolbar-segment-color-checked ///	Color of the checked Segment Buttons in the Toolbar
// --ion-toolbar-segment-background ///	Background of the Segment Buttons in the Toolbar
// --ion-toolbar-segment-background-checked ///	Background of the Segment Buttons in the Toolbar
// --ion-toolbar-segment-indicator-color ///	Color of the Segment Button indicator in the Toolbar
// --ion-item-background ///	Background of the Item
// --ion-item-border-color ///	Border color of the Item
// --ion-item-color ///	Color of the components in the Item
// --ion-placeholder-color

@import "breakpoints";

@mixin queue-width($wt){
  width: $wt;
  --background: #fff;
    border-left: 0.2rem solid #efefef;
    border-right: 0.2rem solid #efefef;
    background-color: #fff;
    box-shadow: 1rem 0rem 1rem rgb(0 0 0 / 5%);
    height: 100vh;
    @media (max-width:767px) {
      width: 100%;
    }
    .apptSlider{
        border-bottom: 0.1rem solid var(--ion-color-gray20);
        border-top: 0.1rem solid var(--ion-color-gray03);

        ion-icon{
            font-size: 2.4rem;
        }
    }
}

.apptqueue{
    @include queue-width(375px);
}

.activityqueue{
  @include queue-width(375px);
}

.notificationqueue{
  @include queue-width(375px);
}
.patientqueue{
    @include queue-width(375px);
}
.main-queue {
  padding: 0;
  background-color: #fff;
  transition: all .3s ease-in-out;
  margin: 1rem 1rem 0 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  @media(max-width: 767px) {
    margin: 0;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }

}

.queue--hidden {
  opacity: 0;
}

// shared
.queue--floating,
.queue--mobile {
    position: absolute;
    top: 0;
    bottom: var(--height--nav--mobile);
    left: 0;
    right: 0;
    z-index: 11;
}

.queue--floating,
.queue--extended,
.queue--pinned {
    border-right: 0.1rem solid #e3e3e3;
}

// unique
.queue--floating {
  left: var(--width--nav);
  right: initial;
  bottom: 0;
  box-shadow: 1rem 0rem 1rem rgb(0 0 0 / 5%);

  &.queue--hidden {
      left: calc(var(--width--nav) - var(--width--queue--condensed));
  }
}
.queue--pinned {
    &.queue--hidden {
        margin-left: calc(var(--width--queue--condensed) * -1);
    }
}

.queue--extended {
    width: var(--width--queue--extended);

    &.queue--hidden {
        margin-left: calc(var(--width--queue--extended) * -1);
    }
}

.queue--mobile {
    &.queue--hidden {
        top: calc((100vh - var(--height--nav--mobile)));
    }
}

.profile-pic {
    margin: 0.6rem 0.8rem;
}


.secondary-text {
    font-size: 1.3rem;
}

// ion-list-header {
//     padding-top: 10px;
//     min-height: 19px;
//     height: 27px;
//     @media (max-width: 991px) {
//       height: 50px;
//     }
//     @media (max-width:575px) {
//       height: 40px;
//     }
// }


ion-item {
    ion-avatar {
        align-items: center;
        justify-content: center;
        display: flex;
        color: var(--ion-color-gray80);
        font-size: 1.8rem;
        margin: 0 0.8rem;

        @mixin border-ring($c, $b, $g: $b) {
            $n: length($c);
            $r: .5*$b;
            $ba: calc(360deg / $n);
            $sl: ();
            $gl: ();

            @for $i from 1 through $n {
                $ca: $i*$ba;
                $sl: $sl,
                nth($c, $i) 0% $ca;
            }

            border: solid $b transparent;
            padding: $g;
            background: conic-gradient($sl);
            border-radius: 50%;
            background-origin: border-box;
            --mask: radial-Gradient(closest-side, red calc(100% - #{$b} - #{$g} - 0.1rem), transparent calc(100% - #{$b} - #{$g}) calc(100% - #{$b}), green calc(100% - #{$b} + 0.1rem) calc(100% - 0.1rem), transparent);
            -webkit-mask: var(--mask);
            mask: var(--mask);
        }

        img {
            &.color_1 {
                @include border-ring(var(--medical-alert--1) var(--medical-alert--2) var(--medical-alert--3) var(--medical-alert--4), 0.4rem, 0.05rem)
            }
            &.color_2 {
                @include border-ring(var(--medical-alert--2) var(--medical-alert--1), 0.4rem, 0.05rem)
            }
            &.color_3 {
                @include border-ring( var(--medical-alert--2) var(--medical-alert--3) var(--ion-color-primary100) var(--medical-alert--1), 0.4rem, 0.05rem)
            }
            &.color_4{
                border: solid 0.4rem transparent;
                padding: 0.05rem;
                background: conic-gradient(var(--ion-color-info60) 0% 120deg, var(--ion-color-primary100) 0% 240deg, var(--medical-alert--2) 0% 360deg);
                border-radius: 50%;
                background-origin: border-box;
                --mask: radial-Gradient(closest-side, red calc(100% - 0.4rem - 0.05rem - 0.1rem), transparent calc(100% - 0.4rem - 0.05rem) calc(100% - 0.4rem), green calc(100% - 0.4rem + 0.1rem) calc(100% - 0.1rem), transparent);
                -webkit-mask: var(--mask);
                mask: var(--mask);
            }
               &.color_5{
                border: solid 0.4rem transparent;
                padding: 0.05rem;
                background:var(--ion-color-primary100);
                border-radius: 50%;
                background-origin: border-box;
                --mask: radial-Gradient(closest-side, red calc(100% - 0.4rem - 0.05rem - 0.1rem), transparent calc(100% - 0.4rem - 0.05rem) calc(100% - 0.4rem), green calc(100% - 0.4rem + 0.1rem) calc(100% - 0.1rem), transparent);
                -webkit-mask: var(--mask);
                mask: var(--mask);
            }
            &.color_6{
                border: solid 0.4rem transparent;
                padding: 0.05rem;
                background:var(--ion-color-gray20);
                border-radius: 50%;
                background-origin: border-box;
                --mask: radial-Gradient(closest-side, red calc(100% - 0.4rem - 0.05rem - 0.1rem), transparent calc(100% - 0.4rem - 0.05rem) calc(100% - 0.4rem), green calc(100% - 0.4rem + 0.1rem) calc(100% - 0.1rem), transparent);
                -webkit-mask: var(--mask);
                mask: var(--mask);
            }
        }
    }

}



ion-searchbar {
    --icon-color: var(--ion-color-light) !important;
    padding: 1.4rem 0.2rem !important;
    margin-left: 1rem;
}

ion-searchbar.searchbar-has-focus {
    --border-color: var(--q-color-brand) !important;
    --box-shadow: 0 0 0 .1rem var(--q-color-brand) !important;
}

.searchbar-input {
    line-height: 2.2rem !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    left: 1.1rem !important;
    top: 0.8rem !important;
    width: 1.8rem !important;
    height: 1.8rem !important;
}

// .item-text {
//     padding: 6px 0;
// }


// ion-item.item-selected {
//     ion-text {
//         color: #fff !important;
//     }

//     .item-text-row {
//         .secondary-text {
//             color: #f5f5f5;
//         }
//     }
// }
.queue {
    --background: #fff;
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border: 0.1rem solid var(--ion-color-gray20);
    box-shadow: 0.4rem 0rem 0.8rem 0rem rgba(0, 0, 0, 0.25);
    @media(max-width: 767px) {
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
    }
}

ion-button.backbtn {
    padding: 0 0.8rem;
}

.patient_txt {
  background-color: var(--ion-color-gray20);
}

.patient_img {
  background-color: var(--ion-color-white);
}

ion-content.queue {
    --background: #fff;
    border-left: 0.2rem solid #efefef;
}
.patient-header{
    ion-button{
        --padding-start: 0 !important;
        --padding-end:0 !important;
    }
}

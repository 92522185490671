:root {
  --q-color-brand: #246f99;
	--q-color-brand-rgb: 36,111,153;
	--q-color-brand-contrast: #ffffff;
	--q-color-brand-contrast-rgb: 255,255,255;
	--q-color-brand-shade: #206287;
	--q-color-brand-tint: #3a7da3;
}

.ion-color-brand {
  --ion-color-base: var(--q-color-brand);
  --ion-color-base-rgb: var(--q-color-brand-rgb);
  --ion-color-contrast: var(--q-color-brand-contrast);
  --ion-color-contrast-rgb: var(--q-color-brand-contrast-rgb);
  --ion-color-shade: var(--q-color-brand-shade);
  --ion-color-tint: var(--q-color-brand-tint);
}

.ion-color-dark {
	--ion-color-base: var(--ion-color-dark);
	--ion-color-base-rgb: var(--ion-color-dark-rgb);
	--ion-color-contrast: var(--ion-color-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-shade);
	--ion-color-tint: var(--ion-color-dark-tint);
}

// Custom Colors for Health Items

.colors-health-1 {
  background: var(--health-object-gradient-1);
  color: var(--ion-color-health-1-contrast)
}
.colors-health-2 {
  background: var(--health-object-gradient-2);
  color: var(--ion-color-health-2-contrast)
}
.colors-health-3 {
  background: var(--health-object-gradient-3);
  color: var(--ion-color-health-3-contrast)
}
.colors-health-4 {
  background: var(--health-object-gradient-4);
  color: var(--ion-color-health-4-contrast)
}
.colors-health-5 {
  background: var(--health-object-gradient-5);
  color: var(--ion-color-health-5-contrast)
}
.colors-health-6 {
  background: var(--health-object-gradient-6);
  color: var(--ion-color-health-6-contrast)
}


:root {
	--ion-color-health-1: #be1e2d;
	--ion-color-health-1-rgb: 190,30,45;
	--ion-color-health-1-contrast: #ffffff;
	--ion-color-health-1-contrast-rgb: 255,255,255;
	--ion-color-health-1-shade: #a71a28;
	--ion-color-health-1-tint: #c53542;

	--ion-color-health-2: #F15A29;
	--ion-color-health-2-rgb: 241,90,41;
	--ion-color-health-2-contrast: #000000;
	--ion-color-health-2-contrast-rgb: 0,0,0;
	--ion-color-health-2-shade: #d44f24;
	--ion-color-health-2-tint: #f26b3e;

	--ion-color-health-3: #4C9C91;
	--ion-color-health-3-rgb: 76,156,145;
	--ion-color-health-3-contrast: #FFF;
	--ion-color-health-3-contrast-rgb: 255,255,255;
	--ion-color-health-3-shade: #438980;
	--ion-color-health-3-tint: #5ea69c;

	--ion-color-health-4: #4F55A2;
	--ion-color-health-4-rgb: 79,85,162;
	--ion-color-health-4-contrast: #ffffff;
	--ion-color-health-4-contrast-rgb: 255,255,255;
	--ion-color-health-4-shade: #464b8f;
	--ion-color-health-4-tint: #6166ab;

	--ion-color-health-5: #2B3990;
	--ion-color-health-5-rgb: 43,57,144;
	--ion-color-health-5-contrast: #ffffff;
	--ion-color-health-5-contrast-rgb: 255,255,255;
	--ion-color-health-5-shade: #26327f;
	--ion-color-health-5-tint: #404d9b;

	--ion-color-health-6-rgb: 146,39,143;
	--ion-color-health-6-contrast: #ffffff;
	--ion-color-health-6-contrast-rgb: 255,255,255;
	--ion-color-health-6-shade: #80227e;
	--ion-color-health-6-tint: #9d3d9a;

}

.ion-color-health-1 {
	--ion-color-base: var(--health-object-gradient-1);
	--ion-color-base-rgb: var(--ion-color-health-1-rgb);
	--ion-color-contrast: var(--ion-color-health-1-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-1-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-1-shade);
	--ion-color-tint: var(--ion-color-health-1-tint);
}

ion-icon.ion-color-health-1 {
  --ion-color-base: var(--ion-color-health-1)
}

.ion-color-health-2 {
	--ion-color-base: var(--health-object-gradient-2);
	--ion-color-base-rgb: var(--ion-color-health-2-rgb);
	--ion-color-contrast: var(--ion-color-health-2-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-2-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-2-shade);
	--ion-color-tint: var(--ion-color-health-2-tint);
}

ion-icon.ion-color-health-2 {
  --ion-color-base: var(--ion-color-health-2)
}

.ion-color-health-3 {
	--ion-color-base: var(--health-object-gradient-3);
	--ion-color-base-rgb: var(--ion-color-health-3-rgb);
	--ion-color-contrast: var(--ion-color-health-3-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-3-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-3-shade);
	--ion-color-tint: var(--ion-color-health-3-tint);
}

ion-icon.ion-color-health-3 {
  --ion-color-base: var(--ion-color-health-3)
}

.ion-color-health-4 {
	--ion-color-base: var(--health-object-gradient-4);
	--ion-color-base-rgb: var(--ion-color-health-4-rgb);
	--ion-color-contrast: var(--ion-color-health-4-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-4-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-4-shade);
	--ion-color-tint: var(--ion-color-health-4-tint);
}

ion-icon.ion-color-health-4 {
  --ion-color-base: var(--ion-color-health-4)
}

.ion-color-health-5 {
	--ion-color-base: var(--health-object-gradient-5);
	--ion-color-base-rgb: var(--ion-color-health-5-rgb);
	--ion-color-contrast: var(--ion-color-health-5-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-5-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-5-shade);
	--ion-color-tint: var(--ion-color-health-5-tint);
}

ion-icon.ion-color-health-5 {
  --ion-color-base: var(--ion-color-health-5)
}

.ion-color-health-6 {
	--ion-color-base: var(--health-object-gradient-6);
	--ion-color-base-rgb: var(--ion-color-health-6-rgb);
	--ion-color-contrast: var(--ion-color-health-6-contrast);
	--ion-color-contrast-rgb: var(--ion-color-health-6-contrast-rgb);
	--ion-color-shade: var(--ion-color-health-6-shade);
	--ion-color-tint: var(--ion-color-health-6-tint);
}

ion-icon.ion-color-health-6 {
  --ion-color-base: var(--ion-color-health-6)
}

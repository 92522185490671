.k-popover-header {
    padding-bottom: 0rem;
}

kendo-dialog{
  .k-dialog-title{
    justify-content: center;
    line-height: 1.6rem;
  }
  .k-dialog {
      width: 41.2rem;
      border-radius: 1rem!important;
      @media (max-width:415px) {
        width:38rem;
      }
      @media (max-width:390px) {
        width:34rem;
      }
  }
  .k-overlay {
    opacity: 0.15 !important;
    }
    .k-input-solid::after{
      border-color: var(--ion-color-primary, #3880ff)!important;
    }
}
.k-dialog-buttongroup{
  padding: 0.8rem 2rem 0.8rem 2rem;
  }

  .k-window-titlebar{
    padding: 1.2rem; //0.8rem 20px 5px 20px
  }
  .total-input .k-input-inner{
    text-align:right!important;
    padding: 0.8rem 0!important;
  }
  .orderpopup .k-dialog .k-window-content {
    max-height:60rem!important;
    @media(max-width: 1280px) {
      max-height: 49rem!important;
    }
    @media(max-width: 1024px) {
      max-height: 47rem!important;
    }
    @media (max-width:415px) {
      max-height:49.5rem!important;
    }
  }
  .k-i-x::before {
    color: var(--ion-color-gray80);
    font-size: 2rem;
}


.prior_auth_popup_main{
  .k-window-titlebar{
    height: 4.8rem !important;
  }
}
.kendo-close-btn{
  position: absolute;
  right: 0.5rem;
 }

 .k-window-actions{
  display: none;
}
.k-window{
  box-shadow: 0 2.4rem 3.8rem rgba(0, 0, 0, 0.14), 0 1.1rem 1.5rem rgba(0, 0, 0, 0.2);
}
.k-window-content, .k-prompt-container{
  padding: 0;
}
kendo-dropdownlist,
kendo-textbox,
kendo-datepicker,
kendo-multiselect,
kendo-dateinput,
kendo-combobox, kendo-numerictextbox, kendo-multicolumncombobox, kendo-textarea{
  ::placeholder{
    font-style: italic !important;
    font-size: 1.4rem !important;
    font-weight: 300 !important;
    color: #9DAAB0;
  }
}

.k-checkbox {
  border-width: 0.1rem !important;
}
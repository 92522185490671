/* This is not intended to replace the ion-grid system (https://ionicframework.com/docs/layout/grid).
Rather, this contains custom flex classes to assist in the top-level layout of the app as a whole. */

/* Alignment defaults to stretch and Justification default to flex-start.
Additional utility classes handling alignments and justification can be found in the ion-grid documents. */

%l-flex {
  display: flex;
  overflow: hidden;
}

.l-row {
  @extend %l-flex;
}
.l-row-wrap {
  @extend %l-flex;
  flex-wrap: wrap;
}
.l-row-center {
  @extend %l-flex;
  align-items: center;
}
.l-column {
  @extend %l-flex;
  flex-direction: column;
}


.l-row-reverse {
  @extend %l-flex;
  flex-direction: row-reverse;
}
.l-column-reverse {
  @extend %l-flex;
  flex-direction: column-reverse;
}

.l-preserve {
  flex: 0 0 auto;
  min-width: 0;
  overflow: visible;
  text-overflow: inherit;
}

%l-fill {
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.l-fill {
  @extend %l-fill;
  &--scroll-y {
    @extend %l-fill;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &--scroll-x {
    @extend %l-fill;
    overflow-x: auto;
    overflow-y: hidden;
  }
  &--scroll-xy {
    @extend %l-fill;
    overflow-x: auto;
    overflow-y: auto;
  }
}

//scrollbar


.simplebar-scrollbar{
  width: 10px!important;
}
.simplebar-scrollbar::before {
  background-color:#787878!important;
}
.simplebar-track.simplebar-vertical {
width: 20px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
//left: 10px;
transition: opacity 0s linear, left 0.2s ease-in-out;
}

.simplebar-track.simplebar-vertical.simplebar-hover .simplebar-scrollbar::before,
.simplebar-dragging .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
right: 0px;
left: 0px;
}

ngx-simplebar{
  left: 1px;
}
.simplebar-scrollbar::before {
  display: none;
//  height: 300px!important;
}
ngx-simplebar:hover{
  .simplebar-scrollbar::before {
    display: inline-block;
  //  height: 300px!important;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
 
}

::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}

.tabs-vertical:hover {
  &::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar:hover {
      width: 10px!important;
    }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2)
  }
}
.episode-content:hover {
  &::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar:hover {
      width: 10px!important;
    }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2)
  }
}
%row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

%column {
  display: flex;
  flex-flow: column nowrap;
}

%flex-wrap {
  flex-wrap: wrap;
}

////////////////////////////////////////////////////////////////////////////////
// Primary Flex Structures

.row {
  @extend %row; }

.row-wrap {
  @extend %row;
  @extend %flex-wrap;
}

.column {
  @extend %column; }

.column-wrap {
  @extend %column;
  @extend %flex-wrap; }


////////////////////////////////////////////////////////////////////////////////
// Allignment Modifiers

.row-top {
  @extend %row;
  align-items: flex-start; }

.row-bottom {
  @extend %row;
  align-items: flex-end; }

.row-baseline {
  @extend %row;
  align-items: baseline; }

.row-right {
  @extend %row;
  justify-content: flex-end; }

.row-center {
  @extend %row;
  justify-content: center; }

.row-verticalCenter {
  @extend %row;
  align-items: center; }


//////////////////////////////////////////////////////////////////////////////////
// Gap Setting

// @TODO size variables w/ ionic
.gap-sm {
  gap: 3px;
}

.gap {
  gap: 5px;
}

.gap-lg {
  gap: 10px;
}

.gap-xl {
  gap: 15px;
}

.gap-xxl {
  gap: 20px;
}


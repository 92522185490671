@mixin avatar($wd, $ht, $lh, $fs){
  width: $wd;
  height: $ht;
  margin-right: 1rem;
  margin-left: 0;
  position: relative;
  text-align: center;
  line-height: $lh;
  font-size: $fs;
  color: var(--ion-color-gray80);  
  font-weight: 400;
  background-color: var(--ion-color-gray20);
  background-color: var(--ion-color-gray20);
}

@mixin status($bg, $ht, $wd, $lh, $fs) {
  position: absolute;
  right: 0;
  bottom: -0.1rem;
  color: #fff;
  background-color: ($bg);
  height: $ht;
  width: $wd;
  border-radius: 50%;
  line-height: $lh;
  text-align: center;
  border: .1rem solid #fff;
  ion-icon{
    font-size: $fs;
    text-align: center;
    font-weight: 600;
  }
}

.square-avatar{
  @include avatar(7rem, 7rem, 7rem, 2.4rem);
  border-radius: 0.5rem;
  margin-left: 0.8rem;
  img{
    border-radius: 0.5rem;
  }
}

.lg-radius-square-avatar {
  @include avatar(7rem, 7rem, 7rem, 2.4rem);
  border-radius: 2rem;
  margin: 0.5rem 1rem 0.5rem 0.5rem;
  
  img {
    border-radius: 2rem;
  }
}
.lg-square-avatar{
  @include avatar(11rem, 11rem, 11rem, 3.2rem);
  border-radius: 0.5rem;
  margin-left: 0.8rem;
  img{
    border-radius: 0.5rem;
  }
}

.default-avatar{
  @include avatar(4rem,4rem,4rem,1.8rem);
}
.msg-active-avatar{
  @include avatar(4rem,4rem,4rem,1.8rem);
  margin: 0;
  border: 0.1rem solid #fff;
}
.small-msg-active-avatar{
  @include avatar(3rem,3rem,3rem,1.4rem);
  margin: 0;
  border: 0.1rem solid #fff;
}

.small-msg-active-avatar {
  font-size: 1.4rem!important
}

.small-active-msg-status{
  @include status(var(--ion-color-success), 0.8rem, 0.8rem, 0.8rem, 0);
  bottom: -0.3rem;
}

.active-msg-status{
  @include status(var(--ion-color-success), 1rem, 1rem, 0.5rem, 0.8rem);
  bottom: -0.2rem;
}

.small-avatar{
  @include avatar(2.4rem, 2.4rem, 2.4rem, 1.4rem);
  margin-right: 0.5rem;
}


.history-avatar{
  @include avatar(3rem, 3rem, 3rem, 1.8rem);
}

//signout avatar
.signout-avatar{
  @include avatar(7rem, 7rem, 7rem, 2.4rem);
  margin-right: 1.5rem;
}

.active-history-status{
  @include status(var(--ion-color-success), 0.8rem, 0.8rem, 0.8rem, 0);
}

.inactive-history-status{
  @include status(var(--ion-color-gray40), 0.8rem, 0.8rem, 0.8rem, 0);
}


.active-default-status{
  @include status(var(--ion-color-success), 1rem, 1rem, 0.5rem, 0.8rem);
}

.inactive-default-status{
  @include status(var(--ion-color-gray40), 1rem, 1rem, 0.5rem, 0.8rem);
}

.busy-default-status{
  @include status(var(--ion-color-secondary80), 1rem, 1rem, 0.5rem, 0.8rem);
}

.dnd-default-status{
  @include status(var(--ion-color-white), 1rem, 1rem, 0.5rem, 0.8rem);
  border: none;
}

.warning-default-status{
  @include status(var(--ion-color-white), 1rem, 1rem, 0.5rem, 0.8rem);
  border: none;
}



.active-small-status{
  @include status(var(--ion-color-success), 0.8rem, 0.8rem, 0.4rem, 0.6rem);
}

.inactive-small-status{
  @include status(var(--ion-color-gray40), 0.8rem, 0.8rem, 0.4rem, 0.6rem);
}

.active-large-status{
  @include status(var(--ion-color-success), 1.6rem, 1.6rem, 1rem, 1.2rem);
  right: 0.2rem;
  bottom: 0.2rem;
}

.inactive-large-status{
  @include status(var(--ion-color-gray40), 1.6rem, 1.6rem, 1rem, 1.2rem);
  right: 0.2rem;
  bottom: 0.2rem;
}

.avatar-border{
  @include avatar(4rem,4rem,4rem,1.8rem);
  padding: 0.1rem;
  border: 0.1rem solid var(--ion-color-white);
  background-color: var(--ion-color-gray100);
}


.note-avatar{
  @include avatar(5rem,5rem,5rem,1.8rem);
}
.active-note-status{
  @include status(var(--ion-color-success), 1.2rem, 1.2rem, 0.6rem, 1rem);
}

.inactive-note-status{
  @include status(var(--ion-color-gray40), 1.2rem, 1.2rem, 1.2rem, 1rem);
}

.comment-list-avatar{
  @include avatar(3rem, 3rem, 3rem, 1rem);
}

.comment-avatar{
  @include avatar(4.6rem,4.6rem,4.6rem,1.8rem);
}
.active-comment-avatar-status{
  @include status(var(--ion-color-success), 1rem, 1rem, 0.4rem, 0.8rem);
}

.inactive-comment-avatar-status{
  @include status(var(--ion-color-gray40), 1rem, 1rem, 0.4rem, 0);
}

.large-status-icon{
  @include status(var(--ion-color-white), 1.6rem, 1.6rem, 1rem, 1.2rem);
  right: 0.2rem;
  bottom: 0.2rem;
}

@mixin medical-group-image($wd,$ht){
  text-align: center;
  width: $wd;
  height: $ht;
  vertical-align: middle;
  display: inline-block;
  border-radius: 0;
}

.default-group-image{
  img{
    @include medical-group-image(1.8rem, 2.3rem);
  }
}

.group-image{
  img{
    @include medical-group-image(3.2rem, 4.1rem);
  }
}

  .medicalCatAvtar {
      align-items: center;
      justify-content: center;
      display: flex;
      color: #fff;
      font-size: 1.6rem;
      margin: 0 0.8rem;

      @mixin border-ring($c, $b, $g: $b) {
          $n: length($c);
          $r: .5*$b;
          $ba: calc(360deg / $n);
          $sl: ();
          $gl: ();

          @for $i from 1 through $n {
              $ca: $i*$ba;
              $sl: $sl,
              nth($c, $i) 0% $ca;
          }

          border: solid $b transparent;
          padding: $g;
          background: conic-gradient($sl);
          border-radius: 50%;
          background-origin: border-box;
          --mask: radial-Gradient(closest-side, red calc(100% - #{$b} - #{$g} - 0.1rem), transparent calc(100% - #{$b} - #{$g}) calc(100% - #{$b}), green calc(100% - #{$b} + 0.1rem) calc(100% - 0.1rem), transparent);
          -webkit-mask: var(--mask);
          mask: var(--mask);
      }

      img {
          &.color_1 {
              @include border-ring(var(--medical-alert--1) var(--medical-alert--2) var(--medical-alert--3) var(--medical-alert--4), 0.4rem, 0.0.5rem)
          }
      }
  }
  .profile-avatar{
      @include avatar(19.6rem,19.6rem,inherit, 9.6rem);
      border: 0.1rem solid #fff;
      font-weight:300;
    }
  .signoutremove-avatar{
    @include avatar(15.6rem,15.6rem,15.6rem,6.4rem);
    border: 0.1rem solid #fff;
    font-weight:300;
  }

  .group-avatar{
   background-color:#EFDAFF ;
   padding: 0.45rem 0.6rem;
   border-radius:50%;
  }
  .user-avatar{
    background-color:#CCF2DE ;
    padding: 0.45rem 0.6rem;
    border-radius:50%;
   }

   .no_patient_avatar {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    margin-left: 0;
    position: relative;
    text-align: center;
    line-height: 4rem;
    font-size: 1.8rem;
    color: var(--ion-color-gray80);
    font-weight: 400;
    padding: 0.4rem;
   // background-color: var(--ion-color-white);
    margin: 0;
  }

  .selected_slider_card{
    border: 0.1rem solid var(--ion-color-primary);
  }
//owl css
.owl-theme .owl-nav {
  margin: 0  !important;
  position: absolute !important;
  top: -3rem !important;
  right: 0 !important;
  display: flex;
}

.owl-theme .owl-nav [class*=owl-]{
  color: #4b5961 !important;
  font-size:1.8rem !important;
  padding: 0.3rem 1rem !important;
  background: #f1f2f3 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  line-height:1rem !important;
}
.owl-theme .owl-nav .owl-prev{
  border: 0.1rem solid #9DAAB0!important;
  border-bottom-left-radius: 0.5rem!important;
    border-top-left-radius: 0.5rem!important;
    border-right: none!important;
}
.owl-theme .owl-nav .owl-next{
  border: 0.1rem solid #9DAAB0!important;
  border-bottom-right-radius: 0.5rem!important;
  border-top-right-radius: 0.5rem!important;
}
.slider_main .owl-carousel .owl-stage{
  transition: all 1.8s ease 0s !important;
}
.slider_main{
  padding: 1rem 0rem;
  position: relative;
}
.slider_main .lab_result_card{
  width: 24rem;
}
.all_text{
    position: absolute;
    top: -1.5rem;
    right: 7rem;
}
.all_text:hover{
  cursor: pointer;
}
.show_slider{
  display: none;
  position: absolute;
  top: -1.5rem;
  right: 0.5rem;
}
.show_slider:hover{
  cursor: pointer;
}

@media only screen and (max-width: 1280px){
  .slider_main .lab_result_card{
    width: 96%;
  }
}


// Slider SCSS
.slick-slider.slider-section {
  width: 100%;
  .slick-slide{
    width: 24rem !important;
  }

}

/* Slider Control */
.slider-controls {
  width: auto;
  justify-content: flex-end;
  display: flex;

  .single-row{
    height: 2.8rem;
    padding-top: 0.4rem;
   }
}

/* slider control button start */
.slider-control-btn{
  ion-button {
    border: 0.1rem solid #9DAAB0;
    margin: 0;
    height: 2.6rem;
    ion-icon{
      font-size: 1.6rem;
      padding: 0rem 0.5rem;
    }
  }
  ion-text{margin-right: 1.5rem;}
  .prev-btn{
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-right: none;
  }
  .next-btn{
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

/* disabled slider control */
.slick-disabled {
  opacity: 0.5;
  pointer-events:none;
}


.slick-slider .slick-track, .slick-slider .slick-list {
  margin-left: 0 !important;
}

.allslides{
  .slider-section{
    display: inline-flex;
    flex-wrap: wrap;

    // @media (max-width:1280px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    // @media (max-width:767px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
    // @media (max-width:575px) {
    //   grid-template-columns: repeat(1, 1fr);
    // }
    ion-card{
      ion-item {
          --min-height: 3rem;
      }
      @media (max-width:575px) {
        width:100%!important;
      }
    }

    .slide{
      width: 24rem !important;
      margin-right:1rem;
      @media (max-width:575px) {
        width: 100% !important;
      }
    }
  }
}
.slider-section{
  ion-card{
    ion-item {
        --min-height: 3rem;
    }
    width:24rem;
  }

}
.slick-slide {
  margin-right: 1rem;
}
// .slick-slide {
//   width:240px!important;
//   // .slide {
//   //   width: 400px !important;
//   // }
// }
@media (max-width:575px) {
.allslides{
ion-card {
  width: 100% !important;
}

}
}




// .episode-info{
//   // .slick-slider .slick-slide{
//   //   width: 333px !important;
//   //   ion-list{
//   //     width: 333px;
//   //   }
//   // }
//   .slick-slide {
//     margin-right: 6px !important;
//   }
// }


.rightPanelControl{
  .controls{
    border:0.1rem solid var(--ion-color-gray40);
    margin: 0;
  }
  .controls.prev{
    border-right: none;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    height: 2.8rem;
    width: 2.8rem;
  }
  .controls.next{
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 2.8rem;
    width: 2.8rem;
  }
  ion-button ion-icon {
    font-size: 1.6rem;
    // padding: 0px 0.5rem;
  }
  ion-button{
    --padding-end: 0;
    --padding-start: 0;
  }
}
.facesheetPopupControl{
  .controls{
    border:0.1rem solid #9DAAB0;
    margin: 0;
  }
  .controls.prev{
    border-right: none;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    height: 2.6rem;
    width: 2.6rem;
  }
  .controls.next{
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 2.6rem;
    width: 2.6rem;
  }
  ion-button ion-icon {
    font-size: 1.6rem;
    // padding: 0px 0.5rem;
  }
  ion-button{
    --padding-end: 0;
    --padding-start: 0;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Local Custom App Wide */
// Please keep custom CSS specific to a certain view or component within that component's own CSS
@import './styles/variables/all';
@import './styles/lavavein/all';
@import './styles/ionic-custom/all';
@import './styles/q/all';
@import './styles/triarq-custom/all';

@import '~quill/dist/quill.bubble.css';
 // or
 @import '~quill/dist/quill.snow.css';


@mixin button {
    height: 4.4rem;
    min-width: 7.5rem;
}

.only-icon-btn{
    @include button;
    --padding-end: 0;
    --padding-start: 0;
    width: 4.4rem;
    min-width: 4.4rem !important;
}

.unfilled-text-btn{
  @include button;
  ion-text{
    padding-top: .1rem !important;
  }
}

.icon-btn,
.btn{
    @include button;
}

.simple-icon{
  font-size: 2.4rem;
}

.filled-icon-btn{
  @include button;

  ion-icon{
    font-size: 2.4rem;
    margin-right: 1rem;
  }
  ion-text{
    padding-top: .1rem !important;
  }
}

.round-icon-button {
  background-color: transparent;
  border-radius: 50%;
  height: 4.4rem;
  width: 4.4rem;
  &:hover{
    border: 0.1rem solid var(--ion-color-highlight) !important;
  }

  @media(max-width: 767px) {
    height: 20px;
    width: 20px;
  }
}
.filter-icon-btn{
  height: 4.4rem;
  width: 4.4rem;
}


// button color classes

.btn-heighlight {
  color: var(--ion-color-white);
  background-color: var(--ion-color-heighlight);
}

//note button
.note-btn{
  .button{
    font-size: 1.2rem;
  }
  ion-button{
    &:hover{
      border: 0.1rem solid var(--ion-color-highlight);
      border-radius: 0.3rem;
      --background: rgba(var(--ion-color-highlight), $alpha: 0.3);
      background: rgba(#ddd, $alpha: 0.2);
    }
  }
}

// details button 

.details-btn{
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  position: relative;
  top: -0.4rem;
  border:0.1rem solid var(--ion-color-gray60);
  height: 2.2rem;
}

// Facesheet expand collapsed button

.small-ic-btn{
  // font-size: 0.3rem !important;
  min-height: 2.6rem !important;
  height: 2.6rem !important;
  width: 2.6rem;
  border-radius: 0.5rem !important;
}
.message-lock-icon{
  height: 3rem;
  width: 3rem;
}
.drawer-icon-size{
  font-size: 2.6rem;
}

.pin-btn{
  button{
      background-color: var(--ion-colr-white);
      :hover{
          background-color: #F2F2F2;
          border-radius: 0.5rem;
      }
  }
  ion-icon{
      padding: 0.3rem;
  }
}
@keyframes slideIn-left {
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes slideIn-right {
  from {
    transform: translateX(-100%)
  }
  to {
    transform: translateX(0)
  }
}


:root {
  // heading font size
  --q-font-size-h1: 9.6rem;
  --q-font-size-h2: 2rem;
  --q-font-size-h3: 4.8rem;
  --q-font-size-h4: 3.4rem;
  --q-font-size-h5: 2.4rem;
  --q-font-size-h6: 2rem;

  //cutome font size

  --q-font-size-custom8: 0.8rem;
  --q-font-size-custom13: 1.3rem;
  --q-font-size-custom15: 1.5rem;
  --q-font-size-custom18: 1.8rem;
  --q-font-size-custom19: 1.9rem;
  --q-font-size-custom20: 2rem;
  --q-font-size-custom22: 2.2rem;
  --q-font-size-custom24: 2.4rem;
  --q-font-size-custom26: 2.6rem;
  --q-font-size-custom28: 2.8rem;
  --q-font-size-custom32: 3.2rem;
  --q-font-size-custom36: 3.6rem;


  // font-weight
  --q-font-weight-light: 300;
  --q-font-weight-medium: 500;
  --q-font-weight-regular: 400;
  --q-font-weight-bold: 700;
  --q-font-weight-extrabold: 800;

  //body text
  --q-body1-font-size: 1.6rem;
  --q-body2-font-size: 1.4rem;
  --q-caption-font-size: 1.2rem;
  --q-overline-font-size: 1rem;

  //font-style
  --q-font-style-italic: italic;

  //letter-spacing
  --q-letter-spacing-minus: -0.015em;
  --q-letter-spacing-25: 0.0025em;
  --q-letter-spacing-15: 0.0015em;
  --q-letter-spacing-5: 0.005em;
  --q-letter-spacing-1: 0.001em;
  --q-letter-spacing-125: 0.0125em;
  --q-letter-spacing-4: 0.004em;

  //text-decoration
  --q-text-uppercase: uppercase ;
  --q-text-lowercase: lowercase ;
  --q-text-capitalize: capitalize ;

  //line-height
  --q-line-heightinherit:inherit;
  --q-line-height11: 1.1rem;
  --q-line-height15: 1.5rem;
  --q-line-height16: 1.6rem;
  --q-line-height18: 1.8rem;
  --q-line-height19: 1.9rem;
  --q-line-height24: 2.4rem;
  --q-line-height28: 2.8rem;
  --q-line-height32: 3.2rem;
  --q-line-height0: 0;

}

// Text decoration
.text-decoration-none{
  text-decoration: none;
}
.text-decoration-underline{
  text-decoration: underline;
}

//Text Transform
.text-transform-initial{
  text-transform: initial;
}

// classes for typography
.h1{
  font-size: var(--q-font-size-h1);
  margin: 0;
}
.h2{
  font-size: var(--q-font-size-h2);
  margin: 0;
}
.h3{
  font-size: var(--q-font-size-h3);
  margin: 0;
}
.h4{
  font-size: var(--q-font-size-h4);
  margin: 0;
}
.h5{
  font-size: var(--q-font-size-h5);
  margin: 0;
}
.h6{
  font-size: var(--q-font-size-h6);
  margin: 0;
}

.body1{
  font-size: var(--q-body1-font-size);
  margin: 0;
}

.body2{
  font-size: var(--q-body2-font-size);
  margin: 0;
}

.custom8{
  font-size: var(--q-font-size-custom8);
}

.custom13{
  font-size: var(--q-font-size-custom13);
}

.custom15{
  font-size: var(--q-font-size-custom15);
}

.custom15-bold{
  font-size: var(--q-font-size-custom15);
  font-weight: var(--q-font-weight-bold);
  letter-spacing: var(--q-letter-spacing-5);
}

.custom18{
  font-size: var(--q-font-size-custom18);
}

.custom19{
  font-size: var(--q-font-size-custom19);
}

.custom20{
  font-size: var(--q-font-size-custom20);
}
.custom22{
  font-size: var(--q-font-size-custom22);
}
.custom20-bold{
  font-size: var(--q-font-size-custom20);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height16);
}

.custom24{
  font-size: var(--q-font-size-custom24);
}
.custom26{
  font-size: var( --q-font-size-custom26);
}
.custom28{
  font-size: var(--q-font-size-custom28);
}
.custom32{
  font-size: var(--q-font-size-custom32);
}

.custom36{
  font-size: var( --q-font-size-custom36);
}


.custom18-bold{
  font-size: var(--q-font-size-custom18);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height16);
}

//classes for font weight
.light-font-weight{
  font-weight: var(--q-font-weight-light);
}
.medium-font-weight{
  font-weight: var(--q-font-weight-medium);
}
.regular-font-weight{
  font-weight: var(--q-font-weight-regular);
}
.bold-font-weight{
  font-weight: var(--q-font-weight-bold);
}
.extrabold-font-weight{
  font-weight: var(--q-font-weight-extrabold);
}

//classes for text decoration

.uppercase{
  text-transform: var(--q-text-uppercase);
}

.lowercase{
  text-transform: var(--q-text-lowercase);
}

.capitalize{
  text-transform: var(--q-text-capitalize);
}



//classes for header, body text and additional
.header1{
  font-size: var(--q-font-size-h1);
  font-weight: var(--q-font-weight-medium);
  letter-spacing: var(--q-letter-spacing-minus);
}
.header2{
  font-size: var(--q-font-size-h2);
  font-weight: var(--q-font-weight-medium);
}
.header2-bold{
  font-size: var(--q-font-size-h2);
  font-weight: var(--q-font-weight-bold);
}
.header3{
  font-size: var(--q-font-size-h3);
  font-weight: var(--q-font-weight-regular);
}
.header4{
  font-size: var(--q-font-size-h4);
  font-weight: var(--q-font-weight-regular);
  letter-spacing: var(--q-letter-spacing-25);
}
.header5{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-medium);
}
.header5-bold{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-bold);
  line-height: var(--q-line-height28);
}
.header5-extrabold{
  font-size: var(--q-font-size-h5);
  font-weight: var(--q-font-weight-extrabold);
  line-height: var(--q-line-height28);
}
.header6{
  font-size: var(--q-font-size-h6);
  font-weight: var(--q-font-weight-medium);
  letter-spacing: var(--q-letter-spacing-15);
}

.body1-txt{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-regular);
  letter-spacing: var(--q-letter-spacing-5);
}
.body1-txt-italic{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  letter-spacing: var(--q-letter-spacing-5);
}
.body1-txt-light{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-light);
  letter-spacing: var(--q-letter-spacing-5);
}
.body1-txt-medium{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-medium);
  letter-spacing: var(--q-letter-spacing-5);
}

.body1-txt-bold{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-bold);
  letter-spacing: var(--q-letter-spacing-5);
}

.body1-txt-extrabold{
  font-size: var( --q-body1-font-size);
  font-weight: var(--q-font-weight-extrabold);
  letter-spacing: var(--q-letter-spacing-5);
}

.body2-txt-light{
  font-size: var( --q-body2-font-size);
  font-weight: var(--q-font-weight-light);
  letter-spacing: var(--q-letter-spacing-25);
  line-height: var(--q-line-height18);
}

.body2-txt{
  font-size: var( --q-body2-font-size) !important;
  font-weight: var(--q-font-weight-regular);
  letter-spacing: var(--q-letter-spacing-25);
  line-height: var(--q-line-height16);
}
.body2-txt-italic{
  font-size: var( --q-body2-font-size);
  font-weight: var(--q-font-weight-regular);
  font-style: var(--q-font-style-italic);
  letter-spacing: var(--q-letter-spacing-25);
}

.body2-txt-medium{
  font-size: var( --q-body2-font-size);
  font-weight: var(--q-font-weight-medium);
  letter-spacing: var(--q-letter-spacing-25);
}

.body2-txt-bold{
  font-size: var( --q-body2-font-size);
  font-weight: var(--q-font-weight-bold);
  letter-spacing: var(--q-letter-spacing-25);
}
.body2-txt-extrabold{
  font-size: var( --q-body2-font-size);
  font-weight: var(--q-font-weight-extrabold);
  letter-spacing: var(--q-letter-spacing-25);
}

.subtitle1{
  font-size: var(--q-body1-font-size);
  letter-spacing: var(--q-letter-spacing-15);
}
.subtitle2{
  font-size: var(--q-body2-font-size);
  letter-spacing: var(--q-letter-spacing-1);
  font-weight: var(--q-font-weight-medium);
}
.button{
  font-size: var(--q-body2-font-size);
  letter-spacing: var(--q-letter-spacing-125);
  font-weight: var(--q-font-weight-medium);
  text-transform: var( --q-text-uppercase);
  --border-radius: 0.5rem;
}
.caption{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-4);
  font-weight: var(--q-font-weight-regular);
}
.caption-capital{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-4);
  text-transform: var( --q-text-uppercase);
}
.caption-bold{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-4);
  font-weight: var(--q-font-weight-bold);
}
.caption-medium{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-4);
  font-weight: var(--q-font-weight-medium);
}
.overline{
  font-size: var(--q-overline-font-size);
  letter-spacing: var(--q-letter-spacing-15);
  text-transform: var( --q-text-uppercase);
}
.overline-bold{
  font-size: var(--q-overline-font-size);
  letter-spacing: var(--q-letter-spacing-15);
  text-transform: var( --q-text-uppercase);
  font-weight: var(--q-font-weight-bold);
}
.larger-overline{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-15);
  text-transform: var( --q-text-uppercase);
}
.caption-italic{
  font-size: var(--q-caption-font-size);
  letter-spacing: var(--q-letter-spacing-4);
  font-style: var(--q-font-style-italic);
}

.additional{
  font-size: var(--q-body2-font-size);
  letter-spacing: var(--q-letter-spacing-15);
  text-transform: var( --q-text-uppercase);
}

.icon-size{
  font-size: 2.4rem;
}
.small-icon{
  font-size: 16px;
}
//style

.italic{
  font-style: var(--q-font-style-italic);
}

.a_link{
  color: var(--ion-color-hightlight);
  text-decoration: none;
}

//line height

.line-normal {
  line-height: 1.2;
}

.line-inherit{
  line-height:var(--q-line-heightinherit);
  }
  .line-0{
    line-height: var(--q-line-height0);
  }
.line-11{
  line-height: var(--q-line-height11);
}
  .line-14{
    line-height: var(--q-line-height14);
  }
.line-15{
  line-height: var(--q-line-height15);
}
.line-16{
  line-height: var(--q-line-height16);
}
.line-18{
  line-height: var(--q-line-height18);
}
.line-19{
  line-height: var(--q-line-height19);
}
.line-22{
  line-height: 2.2rem;
}
.line-24{
  line-height: var(--q-line-height24);
}
.line-32{
  line-height: var(--q-line-height32);
}

@media (max-width: 1060px) {
  .tab-drawer,
  .details-panel {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
  }
  .details-panel {
    background-color: var(--q-app-background-color);
    z-index: 1;
  }
}

@import "breakpoints";

.tab-drawer {
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  width: var(--width--tab-drawer);
  border-left: var(--q-app--border);
  background-color: var(--q-app-background-color);
  // animation: tab-drawer-animation .2s ease;
  z-index: 11; // Above the default ion-tab-bar stuff that is within the health object details (which this can display overtop sometimes)

  // &:not(.is-open) {
  //   display: none;
  // }

  &-header {
    flex-shrink: 0;
  }
  &-body {
    flex-grow: 1;
    overflow: auto;
  }
  &-footer {
    flex-shrink: 0;
  }

  // When the tab drawer is display at the same time that the health object details panel is, we want it to display overtop of it so that we dont have two details panels side by side
  &.is-overDetails {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: -1rem 0rem 1rem 0 rgba(0, 0, 0, 0.05);

  }

}

// @keyframes tab-drawer-animation {
//   from {
//     transform: translateX(100%);
//     width: 0;
//   }
//   to {
//     transform: translateX(0);
//     width: var(--width--tab-drawer);
//   }
// }




body{
  height: fill-available;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

.patient-list {
  overflow-y: auto;
  height: calc(100vh - var(--queue-patient--header));
  height: calc(100dvh - var(--queue-patient--header));
 // mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 5px, #007fc5 5px);
 // mask-size: 100% 20000px;
  mask-position: left bottom;
 // -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 9px, #007fc5 0px);
 // -webkit-mask-size: 100% 20000px;
  //-webkit-mask-position: left bottom;
//  transition: mask-position 0.3s, -webkit-mask-position 0.3s;


  // @media (max-width:767px) {
  //   height: calc(100vh - var(--queue-patient--header) - 36px);
  //   height: calc(100dvh - var(--queue-patient--header) - 36px);
  // }
  @media (max-width:575px) {
    height: calc(100vh - var(--queue-patient--header) - 6rem);
    height: calc(100dvh - var(--queue-patient--header) - 6rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100vh - var(--queue-patient--header) - 125px);
    //   height: calc(100dvh - var(--queue-patient--header) - 125px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100vh - var(--queue-patient--header) - 125px);
    //     height: calc(100dvh - var(--queue-patient--header) - 125px);
    // }
  }
  ion-item {
      --padding-start: 0px;
  }
}

.new-patient--queue{
  ion-item {
    --padding-start: 0px;
}
}
.patient-list.PatientFilter {
  height: calc(100vh - var(--queue-patient--header) - 80px);
  height: calc(100dvh - var(--queue-patient--header) - 80px);
  @media (max-width:575px) {
    height: calc(100vh - var(--queue-patient--header) - 80px);
    height: calc(100dvh - var(--queue-patient--header) - 80px);
  }
}
.order-queue-list{
  overflow-y: auto;
  height: calc(100vh - 146px);
  height: calc(100dvh - 146px);
  @media (max-width:767px) {
    height: calc(100vh - 140px);
    height: calc(100dvh - 140px);
  }
  @media (max-width:575px) {
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100vh - 265px);
    //   height: calc(100dvh - 265px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100vh - 265px);
    //     height: calc(100dvh - 265px);
    // }
  }
}
.order-queue-list.orderFilter{
  overflow-y: auto;
  height: calc(100vh - 261px);
  height: calc(100dvh - 261px);
  @media (max-width:575px) {
    height: calc(100vh - 320px);
    height: calc(100dvh - 320px);
  }
}
.notification-list{
  overflow-y: auto;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  
  @media (max-width:575px) {
    height: calc(100vh - 145px);
    height: calc(100dvh - 145px);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100dvh  - 145px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh - 145px);
    // }
  }
}
.appointment-list{
  overflow-y: auto;
  height: calc(100vh - 190px);
  height: calc(100dvh - 190px);
  
  @media (max-width:575px) {
    height: calc(100vh - 26rem);
    height: calc(100dvh - 26rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100dvh - 340px);
    //   height: calc(100dvh - 340px);
    // }
    
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh  - 340px);
    //     height: calc(100dvh - 340px);
    // }
    
  }
}
.activity-list{
  overflow-y: auto;
  height: calc(100dvh - 6rem);
  height: calc(100vh - 6rem);
  @media (max-width:1024px) {
    height: calc(100vh - 11rem);
    height: calc(100dvh - 11rem);
  }
  @media (max-width:575px) {
    height: calc(100vh - 12rem);
    height: calc(100dvh - 12rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100dvh  - 240px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh - 240px);
    // }
    // //safari
    // @media not all and (min-resolution: .001dpcm)
    //   { @supports (-webkit-appearance:none) {
    //     height: calc(100dvh - 240px);
    //   }}
  }
}
.activity-list.activityFilter{
  overflow-y: auto;
  height: calc(100dvh - 12rem);
  height: calc(100vh - 12rem);
  
  @media (max-width:575px) {
    height: calc(100vh - 17rem);
    height: calc(100dvh - 17rem);
  }
}

.episode-queue-list {
  overflow-y: auto;
  height: calc(100dvh - 7.5rem);
  height: calc(100vh - 7.5rem);
 
  @media (max-width:575px) {
    height: calc(100vh - 16.5rem);
    height: calc(100dvh - 16.5rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100dvh - 235px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh - 235px);
    // }
  }
}
.episode-queue-list.withFilter{
  overflow-y: auto;
  height: calc(100dvh - 11.5rem);
  height: calc(100vh - 11.5rem);
  @media (max-width:575px) {
    height: calc(100vh - 16.5rem);
    height: calc(100dvh - 16.5rem);
  }
}

.calender-list{
  overflow-y: auto;
  height: calc(100vh - 8.2rem);
  height: calc(100dvh - 8.2rem);
  @media (max-width:575px) {
    height: calc(100vh - 18rem);
    height: calc(100dvh - 18rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100dvh - 210px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh - 210px);
    // }
  }
}
.facesheet_list.noAlert{
  overflow-y: auto;
  height: calc(100vh - 5.7rem);
  height: calc(100dvh - 5.7rem);
  @media (max-width:1024px){
    height: calc(100vh - 10rem);
    height: calc(100dvh - 10rem);   
  }
  @media (max-width:575px){
    height: calc(100vh - 18rem);
    height: calc(100dvh - 18rem);   
  }
}
.facesheet_list.withAlert{
  overflow-y: auto;
  height: calc(100vh - 13.7rem);
  height: calc(100dvh - 13.7rem);
  @media (max-width:1024px){
    height: calc(100vh - 19rem);
    height: calc(100dvh - 19rem);   
  }
  @media (max-width:575px){
    height: calc(100vh - 19rem);
    height: calc(100dvh - 19rem);   
  }
}


.lg-popup-content{
  @media (min-height: 1201px) {
    max-height: 80vh;
  }

  /* Styles for extra large devices */
  @media (min-height: 1025px) and (max-height: 1200px) {
      max-height: 80vh;
  }

  /* Styles for large devices */
  @media (min-height: 769px) and (max-height: 1024px) {
      max-height: 80vh;
      @media (max-width:600px){
          max-height: 65vh;
      }
  }

  /* Styles for medium devices */
  @media (min-height: 481px) and (max-height: 768px) {
      max-height: 80vh;
      @media (max-width:600px){
          max-height: 65vh;
      }
  }
  @media (min-height: 376px) and (max-height: 480px) {
    max-height: 48vh;
}

  @media (min-height: 320px) and (max-height: 480px) {
      max-height: 42vh;
  }
}

.md-popup-content{
  @media (min-height: 1201px) {
      max-height: 80vh;
  }

  /* Styles for extra large devices */
  @media (min-height: 1025px) and (max-height: 1200px) {
      max-height: 65vh;
  }

  /* Styles for large devices */
  @media (min-height: 769px) and (max-height: 1024px) {
      max-height: 65vh;
      @media (max-width:600px){
          max-height: 45vh;
      }
  }

  /* Styles for medium devices */
  @media (min-height: 481px) and (max-height: 768px) {
      max-height: 65vh;
      @media (max-width:600px){
          max-height: 38vh;
      }
  }

  @media (min-height: 376px) and (max-height: 480px) {
      max-height: 55vh;
  }
  @media (min-height: 320px) and (max-height: 375px) {
      max-height: 52vh;
  }
}
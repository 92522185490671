:root {
  --white-space-nowrap: nowrap;
  --overflow-hidden: hidden;
  --text-overflow-ellipsis: ellipsis;
  --myWidth: 33.1rem;
}


// ------------TIR--------------//
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-block {
  display: block;
}
.d-inline-block{
  display: inline-block;
}
.align-items-flex-start {
  align-items: flex-start;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: top;
}

.justify-content-end {
  justify-content: flex-end;
}


.justify-content-sb {
  justify-content: space-between;
}

.justify-content-center{
  justify-content: center;
}
.op-80 {
  opacity: 80%;
}

.op-70 {
  opacity: 70%;
}

.vertical-scroll {
  overflow-y: auto;
  height: calc(100vh - 5.7rem);
  height: calc(100dvh - 5.7rem);

  //mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 5px, #007fc5 5px);
 // mask-size: 100% 20000px;
 // mask-position: left bottom;
//  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 6px, #007fc5 5px);
 // -webkit-mask-size: 100% 20000px;
 // -webkit-mask-position: left bottom;
 // transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  // &:hover {
  // //  --webkit-mask-position: left top;
  // }
  @media (max-width:575px){
    height: calc(100vh - 18rem);
    height: calc(100dvh - 18rem);
    // @media screen and (-webkit-min-device-pixel-ratio:0) {
    //   height: calc(100vh  - 250px);
    //   height: calc(100dvh - 250px);
    // }
    // /* Chrome 29+ */
    // @media screen and (-webkit-min-device-pixel-ratio:0)
    //   and (min-resolution:.001dpcm) {
    //     height: calc(100dvh - 255px);
    //     height: calc(100dvh - 250px);
    // }
    // @media not all and (min-resolution:.001dpcm)
    // { @supports (-webkit-appearance:none) {
    //   height: calc(100dvh - 18rem);
    //   height: calc(100dvh - 18rem);
    // }}
  }
}

.fs-20 {
  font-size: 2rem;
}

.text-decoration-none {
  text-decoration: none;
}

// position
.pr {
  position: relative;
}

.pa {
  position: absolute;
}

//pointer
.pointer {
  cursor: pointer;
}

//ion button effect
.no-ripple {
  --ripple-color: transparent;
}
.text-align-left{
 text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// flex

.flex-item {
  display: flex;
  flex-flow: column;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

/* Cancelled */
ion-card.cancelled {
  background: rgba(255, 255, 255, 0.5) !important;

  ion-card-header {
    ion-item {
      background: rgba(255, 255, 255, 0.5) !important;
    }
  }

}

/* end */

.order-panel-footer {
  border-top: 0.1rem solid #CCD4D8;
}

/* Ellipse */
.text-ellipse {
  white-space: var(--white-space-nowrap);
  text-overflow: var(--text-overflow-ellipsis);
  overflow: var(--overflow-hidden);
  display: block;

  @media (max-width:991px) {
    max-width: 40rem;
  }

  @media (max-width:767px) {
    max-width: 28rem;
  }

  @media (max-width:575px) {
    max-width: 24rem;
  }

  @media (max-width:480px) {
    max-width: 20rem;
  }

  @media (max-width:375px) {
    max-width: 18rem;
  }
}

.truncateText {
  white-space: var(--white-space-nowrap);
  overflow: var(--overflow-hidden);
}

/* ion-text color */
ion-text[color="gray100"] {
  color: var(--ion-color-gray100);
}

ion-text[color="gray80"] {
  color: var(--ion-color-gray80);
}

ion-text[color="gray60"] {
  color: var(--ion-color-gray60);
}

ion-text[color="success"] {
  color: var(--ion-color-success);
}

ion-text[color="white"] {
  color: var(--white) !important;
}

ion-text[color="primary"] {
  color: var(--ion-color-primary);
}

ion-text[color="success"] {
  color: var(--ion-color-success) !important;
}

.item-text-row-secondary,
.item-text-row-primary {
  ion-text[color="gray100"] {
    color: var(--ion-color-gray100);
  }

  ion-text[color="gray80"] {
    color: var(--ion-color-gray80);
  }

  ion-text[color="gray60"] {
    color: var(--ion-color-gray60);
  }

  ion-text[color="success"] {
    color: var(--ion-color-success);
  }

  ion-text[color="white"] {
    color: var(--white) !important;
  }

  ion-text[color="primary"] {
    color: var(--ion-color-primary);
  }

  ion-text[color="success"] {
    color: var(--ion-color-success);
  }

  ion-text[color="error100"] {
    color: var(--ion-color-error100);
  }
}

//risk icons css

.risk-square-box {
  font-size: 0.6rem;
  white-space: nowrap;

  ion-icon {
    margin-right: 0.1rem;
  }
}

//on selected item
// ion-item.item-selected ion-text {
//   color: var(--ion-color-white) !important;
// }

.align-items-flex-end {
  align-items: flex-end;
}

.flex-flow-row-reverse {
  flex-flow: row-reverse;
}

//Add utilization modal scss
ion-modal.episodeAddModal {
  --height: content-fit;
  --width: 41.2rem !important;
  --border-radius: 1rem;
  --box-shadow: 0 2.8rem 4.8rem rgba(0, 0, 0, 0.4);
  box-shadow: 0rem 2.4rem 3.8rem rgba(0, 0, 0, 0.14), 0 1.1rem 1.5rem rgba(0, 0, 0, 0.2);
  --border: 0.1rem solid #CCD4D8 !important;
  border: 0.1rem solid #CCD4D8 !important;
  --background: var(--white);
}

.upload .k-button {
  border-width: 0.2rem;
  text-transform: uppercase!important;
  box-shadow: none;
  color: var(--ion-color-primary, #3880ff);
  background-color: #fff;
  border-color: var(--ion-color-primary, #3880ff) !important;
  // border-width: 0.1rem !important;
}

.k-input-solid:focus-within {
  border-bottom: var(--ion-color-primary, #3880ff) !important;
}

.k-input-solid::after {
  border-color: var(--ion-color-primary, #3880ff) !important;
}


.k-input-solid.k-invalid,
.k-input-solid.ng-invalid.ng-touched,
.k-input-solid.ng-invalid.ng-dirty {
  border-bottom: 0.1rem solid #f31700 !important;
}

//modal scss
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.fade {
  transition: opacity .15s linear;
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.fade .modal-dialog {
  position: fixed;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 51.2rem;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  position: relative;
  width: 51.2rem;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .3rem;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: 51.2rem;
  margin: .5rem;
  pointer-events: none;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0.1rem solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.editU {
  cursor: pointer;

  &:hover {
    color: var(--ion-color-primary, #3880ff) !important;
  }
}

.delete {
  cursor: pointer;

  &:hover {
    color: var(--ion-color-error100);
  }
}

.float-right{
  float: right;
}

.k-popup.k-popup-transparent {
  border-radius: 1rem !important;
  //   margin-left: 130px;
}

.k-tooltip {
  border-radius: 0.5rem;
  padding: 0 !important;
}

.k-animation-container {
  border-radius: 1rem;
  max-width: 40vw;
}

//  .k-callout{
//   margin-left: -65.00781px!important;
//  }

//Note
.note {
  padding: 0.5rem 1rem;
  background-color: var(--note-bg);
  border: 0.05rem solid #9DAAB0;
}

.note2 {
  padding: 0.5rem 1rem;
  background-color: var(--note-bg);
  border: 0.1rem solid #9DAAB0;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--ion-color-gray80) !important;
  color: #007fc5 !important;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("/../../../../../../../assets/svg/radiob.svg") !important;
}

.required {
  color: #d32f2f;
}

.details-panel {
  background-color: #fff;
  // position: fixed;
  // right: 0;
  // bottom: 0;
  // top: 57px;
}

kendo-timepicker {
  .k-i-clock {
    display: none !important;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0.8rem 0.8rem !important;
  }

}

.heading-width {
  width: 100%;
  max-width: 20rem;
}

.showHideCheckBox {
  .k-checkbox {
    border-color: var(--ion-color-gray60);
  }

  .k-checkbox:checked,
  .k-checkbox.k-checked {
    background-color: var(--ion-color-highlight);
  }
}

//for check
.activity {
  kendo-textbox {
    background-color: var(--white) !important;
    color: var(--ion-color-gray100);
    border-bottom: 0.1rem solid var(--ion-color-gray60);
    position: relative;
  }
}

.cancelled-mark {
  background-color: var(--ion-color-gray20);
  padding: 0.5rem;
}

.patient_popup_main {
  .patient_reg_main.patient-edit-screen {
    box-shadow: none;
    height: 60rem;
    overflow: auto;
    padding: 2.5rem 3rem !important;
    @media(max-width: 1366px){
      height: 47.5rem;
    }
    @media(max-width:1024px){
      height: 47.5rem;
    }
  }

}
.quick_patient_main_popup.patient_popup_main {
  .patient_reg_main.patient-edit-screen {
    box-shadow: none;
    height: 50rem;
    overflow: auto;
    padding: 2.5rem 3rem !important;
  }
}

//for physician grp
.patient_reg_main.patient-dailogue-box {
  .physicianGrpListRegPage {
    display: block;
  }

  .physicianGrpListUserPage {
    display: none;
  }

  .physicianList {
    margin-left: 0;
  }

}

// ::ng-deep{
//   .patient_reg_main.patient-dailogue-box{
//     .physicianGrpListRegPage{
//       .k-form .k-form-field{
//         margin-top: 0 !important;
//       }
//     }
//   }
// }

.patient_popup_main {
  #patientBasicInfo {
    display: none;
  }

  #patientEditBasicInfo.d-none {
    display: block;
  }
}

.k-input-md .k-input-inner {
  padding: 0.8rem 0.6rem;
}

.wrapper {

  position: relative;

}



//kendo scheduler

.k-scheduler-views-wrapper .k-views-dropdown {
  display: block;
}

.k-scheduler-views {
  display: none;
}

.k-scheduler-toolbar {
  background-color: #F4F4F5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.k-toolbar::before {
  content: "";
  height: 0;
  margin-inline-start: 0;
  display: none;
}

// .k-scheduler-table{
//   .k-slot-cell{
//     display: none;
//   }
// }
.k-toolbar {
  box-shadow: none;
}

.k-scheduler-table {
  border-color: #F4F4F5;
}

.k-scheduler-pane .k-scheduler-times .k-scheduler-table {
  background-color: #F4F4F5;
  border-color: #F4F4F5;
}


.icon-medium {
  width: 1.8rem;
  height: 1.8rem;
}

// .icon-small{
//   width: 8px;
//   height: 8px;
// }

#selectDateOption {
  select {
    option {
      background-image: url('../../assets/img/logo.png');
    }
  }

}

// kendo-scheduler-toolbar{
//   .k-spacer{
//     display: none !important;
//   }
// }

kendo-scheduler .k-event,
.k-event {
  padding: 0 !important;
  min-height: 3.6rem;
}

kendo-scheduler .k-event .k-event-actions:first-child,
.k-event .k-event-actions:first-child {
  margin-top: 0;
}

.k-event.k-selected {
  border: none;
  box-shadow: none;
}

.k-event {
  background-color: transparent;
}

.wrapper {
  position: relative;
}

.onhoverhighlight:hover {
  color: var(--ion-color-highlight);
  font-weight: 700;
}


::ng-deep {

  .k-toolbar .k-input,
  .k-toolbar .k-picker {
    width: auto;
    min-width: 15rem !important;
    max-width: 15rem !important;
  }


}

.CalenderDateOption {
  .k-input-value-text {
    display: flex !important;

    // align-items: self-start !important;
    // align-content: center !important;
    ion-icon {
      --ion-color-base: var(--ion-color-highlight);
    }

    ion-text {
      color: #007fc5 !important;
    }
  }

  .k-picker-solid:focus-within {
    border-color: #F4F4F5 !important;
  }
}


//for appointment-right-panel-calender

.align-items-flex-end {
  align-items: flex-end;
}

.right-panel {
  .patient-header-tab-bar {
    --background: #ffffff !important;
    border-bottom: 0.4rem solid var(--q-color-primary) !important;
    text-align: left !important;
  }

  .mycomponent-wider-popover {
    --width: 95% !important;
    --max-width: 40rem !important;
  }

  .patientTabs-dropdown {
    width: 100% !important;

  }

  .patient-demographics-summary-header--condensed {
    .chevron-icon {
      display: none;
    }
  }

  .patient-demographics-summary {
    background-color: #fff !important;
    width: 38.8rem !important;
  }
}

//Note
.note {
  padding: 0.5rem 1rem;
  background-color: var(--note-bg);
  border: 0.05rem solid #9DAAB0;
}

.note2 {
  padding: 0.5rem 1rem;
  background-color: var(--note-bg);
  border: 0.1rem solid #9DAAB0;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--ion-color-gray80) !important;
  color: #007fc5 !important;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("/../../../../../../../assets/svg/radiob.svg") !important;
}

.required {
  color: #d32f2f;
}

.details-panel {
  background-color: #fff;
  // position: fixed;
  // right: 0;
  // bottom: 0;
  // top: 57px;
}

kendo-timepicker {
  .k-i-clock {
    display: none !important;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0.8rem 0.8rem !important;
  }

}

.heading-width {
  width: 100%;
  max-width: 20rem;
}

.showHideCheckBox {
  .k-checkbox {
    border-color: var(--ion-color-gray60);
  }

  .k-checkbox:checked,
  .k-checkbox.k-checked {
    background-color: var(--ion-color-highlight);
  }
}

.filter_cancel {
  .filled-icon-btn ion-icon {
    font-size: 2rem;
    margin-right: 0rem;
  }

  .filled-icon-btn {
    width: 6.8rem;
    height: 3.4rem;
  }

  .close_btn {
    border-right: .05rem solid #fff;
    padding-right: 0.5rem;
  }

  .button {
    --border-radius: 2rem;
  }
}

.k-scheduler-fullday {
  display: none
}

::ng-deep {
  .patientTabs-dropdown.k-icon {
    color: var(--ion-color-highlight) !important;
  }

}


.calender_right_panel {
  .edit-patient-footer {
    left: auto !important;
    width: 38.8rem !important;
  }
}

//Registration patient component footer
.patient_reg_main {
  .edit_patient_main_container {
    .edit-patient-footer {
      display: none;
    }

    .kedno_footer {
      display: block !important;
    }
  }

  .k-form-field-disabled {
    background-color: #fff;
  }
}

// @media (max-width:1024px) {
//   .patient_popup_main {
//     .patient_reg_main.patient-edit-screen {
//       height: 600px !important;
//       padding: 0px !important;
//     }
//   }
// }

@media (max-width:575px) {

  .patient_popup_main {
    .patient_reg_main.patient-edit-screen {
      height: 40rem !important;
      padding: 0 !important;
    }
    .profile-avatar{
      height: 17.5rem;
      width: 17.5rem;
    }
  }
  .quick_patient_main_popup.patient_popup_main {
    .patient_reg_main.patient-edit-screen {
      box-shadow: none;
      height: 48rem !important;
      overflow: auto;
      padding: 0.5rem !important;
    }
  }
  .k-animation-container {
    width: auto !important;
  }

  .kedno_footer {
    .filled-icon-btn ion-icon {
      display: none !important;
    }
  }

  .patient_reg_main.patient-edit-screen {
    padding: 0rem !important;
  }

  .k-input-md .k-input-inner {
    padding: 0.8rem 0.5rem;
  }

}

//for Queues
.mid-comment-section {
  text-align: center;
  position: relative;

  ion-text {
    span {
      position: relative;
      background: #fff;
      padding: 0 1rem;
    }

    &::before {
      position: absolute;
      border: 0.1rem dashed var(--ion-color-gray40);
      left: calc(50% - 30%);
      width: 60%;
      height: 0;
      content: "";
      top: 50%;
    }
  }

}

.message_container {
  .mid-comment-section {
    height: 3rem;
    display: grid;
    align-items: center;
    width: 72%;
    margin: 1.2rem auto;

    ion-text {
      span {
        background: #f1f2f3;
        padding: 0 2rem;
      }

      &::before {
        position: absolute;
        border: 0.1rem solid var(--ion-color-gray20);
        left: 0;
        width: 100%;
        height: 0;
        content: "";
        top: 50%;
      }
    }
  }

}

// History popup changes
.history_popup_main {

  // .chart-modal-content{
  //   height: max-content !important;
  // }
  .history_redirect_icon {
    display: block !important;
  }
}



::ng-deep {
  .signup_popup_dailog_main {
    .k-input-md .k-input-inner {
      padding: 0.8rem 0.5rem !important;
    }
  }
}

.notificationqueue {
  ion-item {
    --padding-end: 0rem;
    --inner-padding-end: 0rem;
  }

  kendo-label {
    .k-label {
      color: #4B5961 !important;
    }
  }
}

::ng-deep {
  .newEpisode_main {
    .subProcedure {
      .k-form .k-form-field {
        margin-top: 0rem !important;
      }
    }
  }


}

.msgUpload .k-button {
  color: var(--ion-color-highlight);
}

/* Episode Document */
.documents {
  position: relative;

  .external-link {
    position: absolute;
    top: 34%;
    left: 43%;
    transform: translate(0%, 0%) scale(0);

    ion-icon {
      padding: 1rem;
      background: #9DAAB0;
      border-radius: 6.3rem;
      color: var(--ion-color-white);
      font-size: 2.4rem;
    }
  }

  .document-img {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: rgba($color: #000000, $alpha: 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
  }

  &:hover {
    .external-link {
      transform: translate(0%, 0%) scale(1);
    }

    .document-img {
      &::after {
        opacity: 1;
      }
    }
  }
}

.vitalModal,
.documentModal,
.chartModal {
  --border-radius: 1rem !important;
  box-shadow: 0rem 2.4rem 3.8rem rgba(0, 0, 0, 0.14), 0rem 1.1rem 1.5rem rgba(0, 0, 0, 0.2);
}


// Episode Status Card Css

.planned {
  background: #007FC50D;
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--ion-color-primary100);
  border-radius: 0.5rem;
}

.finished {
  background: #4CAF500D;
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--ion-color-success100);
  border-radius: 0.5rem;
}


.canceled {
  background: #A03F1D1A;
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--ion-color-error100);
  border-radius: 0.5rem;
}

.tooltiplist {
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 10%;
  justify-content: space-between;
  line-height: 2.4rem;
  cursor: pointer;

  .box-alert {
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 0.5rem;
    background-color: var(--ion-color-error100);
    border: 0.1rem solid white;
  }
}

.QP_container {
  width: 72%;
  margin: 1.2rem auto;
}

.left-side-container {
  max-width: 100rem;
}

.spinner-pn{
  --color: var(--q-color-primary)!important
}
@media (max-width:375px) {
  .width-120{
    width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
  }
  .header-ios.ion-no-border ion-toolbar:last-of-type{
    padding: 0;
  }
}

ion-navbar.toolbar.toolbar-ios.statusbar-padding,
ion-navbar.toolbar-ios ion-title.title-ios,
ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
ion-toolbar.toolbar-ios ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}



ion-app{
margin-top: env(safe-area-inset-top);
// margin-bottom: env(safe-area-inset-bottom)
}

.activeRxMedContent{
  background-color: var(--ion-color-highlight);
  ion-text{
      color:#ffffff !important;
  }
  ion-icon.white{
    --ion-color-base: white
  }
}



.showScrollOnHover{
  mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 0.5rem, #007fc5 0.5rem);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 0.6rem, #007fc5 0.5rem);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
   &:hover {
 	mask-position: left top;
      -webkit-mask-position: left top;
   }
}
.k-window-content{
  overflow-x:hidden ;
}

.open_active_dropdown{
  background-color: #fff;
  /* position: absolute; */
  //width: max-content;
  right: 0;
  padding: 1rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.15);
  }

  // .queue--pinned-activity{
  //   .activityqueue{
  //     box-shadow: none !important;
  //   }
  //  }

   .queue--pinned, .queue--pinned-activity, .queue--pinned-order, .queue--pinned-appt{
    .apptqueue, .activityqueue, .notificationqueue {
      box-shadow: none !important;
    }
   }


#todoRightMain{
  .rightPanelControl{
      .controls{
          border:0.1rem solid #ffffff !important;
      }
  }
  .add_prescription_main{
    .hideOnTodoRight{
      display: none;
    }
    .todoRightPanelRxFooter{
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: var(--white);
    }
    .showOnToDoRightPanel{
      display: block;
    }
  }

}

#todoRightMain.todo_right_main{

  .add_prescription_main{
    overflow: auto;
      height: 100vh;
      max-height: calc(100vh - 20rem);
      ion-button{
        --padding-start:1.1em !important;
        --padding-end:1.1em !important;
      }
      @media(max-width:1000px){
        max-height: calc(100vh - 26rem);
        max-height: calc(100dvh - 26rem);
      }
      @media (max-width:575px){
        max-height: calc(100vh - 35rem);
        max-height: calc(100dvh - 35rem);
      }
  }
}
#todoRightMain.todo_right_main_full{
  .add_prescription_main{
    overflow: auto;
      height: 100vh;
      max-height: calc(100vh - 18rem);
      ion-button{
        --padding-start:1.1em !important;
        --padding-end:1.1em !important;
      }
      @media(max-width:1000px){
        max-height: calc(100vh - 25rem);
        max-height: calc(100dvh - 25rem);
      }
      @media (max-width:575px){
        max-height: calc(100vh - 30rem);
        max-height: calc(100dvh - 30rem);
      }
  }
}

#todoRightMain.todo_right_main{
  .todotasklist_main{
    overflow: auto;
    height: 100vh;
    max-height: calc(100vh - 20rem);
    ion-button{
      --padding-start:1.1em !important;
      --padding-end:1.1em !important;
    }
    @media(max-width:1000px){
      max-height: calc(100vh - 25rem);
      max-height: calc(100dvh - 25rem);
    }
    @media (max-width:575px){
      max-height: calc(100vh - 30rem);
      max-height: calc(100dvh - 30rem);
    }
  }
}

#todoRightMain.todo_right_main_full{
  .todotasklist_main{
    overflow: auto;
    height: 100vh;
    max-height: calc(100vh - 18rem);
    ion-button{
      --padding-start:1.1em !important;
      --padding-end:1.1em !important;
    }
    @media(max-width:1000px){
      max-height: calc(100vh - 25rem);
      max-height: calc(100dvh - 25rem);
    }
    @media (max-width:575px){
      max-height: calc(100vh - 30rem);
      max-height: calc(100dvh - 30rem);
    }
  }
}
//Task List style
#todoRightMain.todo_right_main {
  .lab_result_list {
    overflow: auto;
    height: 100vh;
    max-height: calc(100vh - 19.5rem);
    max-height: calc(100dvh - 19.5rem);
    max-height: calc(100svh - 19.5rem);

    @media(max-width:1024px) {
      max-height: calc(100vh - 28rem);
      max-height: calc(100dvh - 28rem);
      max-height: calc(100svh - 28rem);
    }
    @media (max-width:575px) {
      min-height: calc(100vh - 35rem);
      min-height: calc(100svh - 35rem);
      min-height: calc(100dvh - 35rem);
      max-height: calc(100vh - 36rem);
      max-height: calc(100dvh - 36rem);
      max-height: calc(100svh - 36rem);
    }
  }
}
#todoRightMain.todo_right_main_full {
  .lab_result_list {
    overflow: auto;
    height: 100vh;
    max-height: calc(100vh - 19rem);

    @media(max-width:1024px) {
      max-height: calc(100vh - 25rem);
      max-height: calc(100dvh - 25rem);
      max-height: calc(100svh - 25rem);
    }
    @media(max-width:780px) {
      max-height: calc(100vh - 30rem);
      max-height: calc(100dvh - 30rem);
      max-height: calc(100svh - 30rem);
    }
    @media (max-width:575px) {
      max-height: calc(100vh - 24rem);
      max-height: calc(100dvh - 24rem);
    }
  }
}
.previewTextArea{
  background-color: var(--ion-color-gray05);
  // border: 0.1rem solid var(--ion-color-gray20);
  padding: 5px 1rem;
}

// @media(max-width: 1366px) {
//   max-height: 500px;
// }
// @media(max-width: 1024px) {
//   max-height: 473px;
// }

.queue-list{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  max-height: 100svh;
  max-height: 100dvh;
  min-height: 400px;

  @media(max-width:767px){
    max-height: calc(100vh - 5.5rem);
    max-height: calc(100dvh - 5.5rem);
    max-height: calc(100svh - 5.5rem);
  }
}

.new-patient--queue{
  flex-grow: 1;
  height: 100%;
 //overflow-y: scroll;
 overflow-x: hidden;
}

.right-panel-list{
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: calc(100vh - 5.9rem);
  max-height: calc(100svh - 5.9rem);
  max-height: calc(100dvh - 5.9rem);
  min-height: 400px;
 // padding-bottom: 0;
  @media(max-width:767px){
    max-height: calc(100vh - 17.5rem)!important;
    max-height: calc(100dvh - 17.5rem)!important;
    max-height: calc(100svh - 17.5rem)!important;
  }
  @media (min-width:767px) and (max-width:886px){
    max-height: calc(100vh - 14.3rem)!important;
    max-height: calc(100dvh - 14.3rem)!important;
    max-height: calc(100svh - 14.3rem) !important;
  }
  @media (min-width:900px) and (max-width:1024px){
    max-height: calc(100vh - 6.5rem)!important;
    max-height: calc(100dvh - 6.5rem)!important;
    max-height: calc(100svh - 6.5rem) !important;
  }
  @media (width:540px){
    max-height: calc(100vh - 19.9rem) !important;
    max-height: calc(100dvh - 19.9rem) !important;
    max-height: calc(100svh - 19.9rem) !important;
  }
}

.right-panel-list.care_plan_timeline_main{
  @media(max-width:767px){
    max-height: calc(100vh - 19.9rem)!important;
    max-height: calc(100dvh - 19.9rem)!important;
    max-height: calc(100svh - 19.9rem)!important;
  }
}
.right-panel-list.care_plans_details{
  @media(max-width:767px){
    max-height: calc(100vh - 22rem)!important;
    max-height: calc(100dvh - 22rem)!important;
    max-height: calc(100svh - 22rem)!important;
  }
}
// .chart-right-panel-list{
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   max-height: calc(100vh - 59px);
//   max-height: calc(100svh - 59px);
//   max-height: calc(100dvh - 59px);
//   min-height: 400px;
// }

//Toaster color
.toast-info, .toast-error, .toast-success, .toast-warning{
  box-shadow: none !important;
}
.toast-container .ngx-toastr{
  padding: 1rem 1rem 1rem 4rem!important;
  min-width:30rem!important;
  max-width: 40rem!important;
  width: 100%!important;
  border-radius: 1rem!important;

  @media(max-width:767px){
  max-width: 30rem!important;
  }
}
.toast-message{
  width: 80%;
  font-size: 1.4rem;
  font-weight: 400;
}
.toast-container .toast-title {
  width: 80%;
  font-size:1.6rem!important;
  font-weight: var(--q-font-weight-bold);
}
.toast-info {
  background-color: var(--ion-color-alert-Primary100) !important;
  color: var(--ion-color-white);
  background-image: url("../../assets/svg/Info-white.svg")!important;
  background-position: 1rem 1rem!important;

}
.toast-error {
  background-color: var(--ion-color-error100) !important;
  color: var(--ion-color-white);
  background-image: url("../../assets/svg/warning-pentagon.svg")!important;
  background-position: 1rem 1rem!important;
}
.toast-success {
  background-image: url("../../assets/svg/tick-circle.svg")!important;
  background-color: var(--ion-color-alert-success100) !important;
  color:var(--ion-color-black)!important;
  background-position: 1rem 1rem!important;
 // background-image: url("../../assets/svg/check-circle.svg");
}
.toast-warning {
  background-color:var(--ion-color-alert-Warning100) !important;
  color:var(--ion-color-black)!important;
  background-image: url("../../assets/svg/warning-triangle.svg")!important;
  background-position: 1rem 1rem!important;
}

button.toast-close-button  {
    position: absolute!important;
    border: 0.1rem solid var(--ion-color-white)!important;
    padding: 0 0.5rem 0.1rem 0.5rem !important;
    border-radius: 0.5rem!important;
    height: 2.1rem !important;
    width: 2.1rem !important;
    font-size: 1.8rem;
    font-weight: 400;
    transform: translate(-0%, -50%);
    top: 50%!important;
    right: 1rem!important;
    text-shadow: none!important;
}
.toast-close-button:hover, .toast-close-button:focus {
  color :var(--ion-color-white)!important;
  text-decoration: none!important;
  cursor: pointer!important;
  opacity:10!important;
}

.toast-warning {
  button.toast-close-button  {
  border: 0.1rem solid  var(--ion-color-gray80)!important;
      color:var(--ion-color-gray100)!important;
  }
  .toast-close-button:hover{
    color:var(--ion-color-gray100)!important;
  }
}

  .toast-success {
    button.toast-close-button  {
      display: none!important;
    }
}

.panelbar-wrapper{
  .todobtnmain{
    display: none !important;
  }
  .right-panel-list{
    max-height: calc(100vh - 15rem);
    max-height: calc(100dvh - 15rem);
    max-height: calc(100svh - 15rem);
  }
}


/* For Out of office */
.status-text, .status-close{
  color: #92278F;
}

.status-text{
  width: 95%;
  line-height: normal;
}

.status-close{
  width: 4%;
  font-size: 2rem;
}

.status-bg{
  background-color: #FFF8FF;
  border: 0.1rem  solid #EFDAFF;
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

.k-outof-office-notification {
  background-color: #fbddfb !important;
  color: hsl(302, 58%, 36%) !important;
  background-image: url('../../assets/svg/log-in-square.svg') !important;
  background-position: 1rem 1.1rem !important;
  border: 0.1rem solid #92278F;
  background-repeat: no-repeat;
  padding: 0.8rem;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  // right: 0.1rem;
  margin-right: 1rem;



  min-width: 45rem !important;
  max-width: 115rem;

  @media(max-width: 1280px) {
    min-width: 50rem !important;
    max-width: 60rem;
  }

  @media(max-width: 767px) {
    min-width: 30rem !important;
    max-width: 50rem;
    margin-right: 0.8rem;
  }

  @media(max-width: 575px) {
    min-width: 30rem !important;
    max-width: 30rem;
    margin-right: 0.5rem;
  }

  .k-notification-content {
    margin-left: 3.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .k-notification-close-action {
    color: #92278F !important;
    border: 0.1rem solid #ffdaff !important;
    font-size: 2rem;
    cursor: pointer;
  }
}

.right-panel{
  patient-tabs{
    .tab_container{
      background-color:#ffffff !important ;
    }
  }
}

.showOnAlertBanner{
  display: none;
}
.hideOnAlertBanner{
  display: block;
}
.alertTopBanner{
  .alert-banner{
    margin: 0 !important;
    .alert_banner_main{
      box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.15) !important;
    }
  }
  .showOnAlertBanner{
    display: block;
  }
  .hideOnAlertBanner{
    display: none;
  }
}

::ng-deep{
  .patRegCheckBox{
    .k-checkbox{
      border-color:#4B5961 !important
    }
  }
}

.calenderRight{
  .facesheet-popup-content{
    max-height: calc(100vh - 18rem) !important;
    max-height: calc(100vh - 18rem) !important;
    max-height: calc(100vh - 18rem) !important;
  }
}
.popup-content-padding {
  padding: 3rem;
}


.todo_left_queue_main{
  .left_queue_task_list{
    display: block;
  }
  .right_queue_task_list{
    display: none;
  }
}

#todoRightPanelMain{
  .left_queue_task_list{
    display: none;
  }
  .right_queue_task_list{
    display: block;
  }
}
#todoRightPanelMain{
  .patient-list {
      max-height: calc(100vh - 19.5rem);
      max-height: calc(100dvh - 19.5rem);
      max-height: calc(100svh - 19.5rem);
  }
}


@media (max-width: 575px) {
  .zip_code.k-animation-container {
      width: 30.3rem!important;
  }
}
.lab_result_popup{
  .show_on_facesheet_popup{
    display: flex !important;
  }
}
.chart_lab_result_detail{
  .show_on_facesheet_popup{
    display: flex !important;
  }
}

.line-clamp-3{
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .lab_result_details_list{
//   max-height: calc(100vh - 175px);
//   max-height: calc(100dvh - 175px);
//   max-height: calc(100svh - 175px);
//   @media(max-width:1000px){
//       max-height: calc(100vh - 250px);
//       max-height: calc(100dvh - 250px);
//   }
//   @media(max-width: 767px) {
//       max-height: calc(100vh - 300px);
//       max-height: calc(100dvh - 300px);
//       max-height: calc(100svh - 300px);
//   }
// }


.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .15);
}

.history_popup_main{
  .medication-panel-footer{
    border-top: .1rem solid var(--ion-color-gray40);
    position: relative;
    top: 0 !important;
    background-color: #fff;
  }
}

.header-border{
  border-bottom: 0.1rem solid var(--ion-color-gray40);
}

.border-frame{
  border: 0.1rem solid var(--ion-color-gray20);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: var(--white);
  margin-left: 1rem;
}

ion-searchbar{
  --placeholder-font-style: italic;
  --placeholder-font-size: var(--q-body2-font-size);
}

.comment-info {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8rem;
}

.right-panel-comment {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem;
  height: max-content;
  max-height: 8rem;
}


.hover_dropdown{
  &:hover{
    background-color: #f2f2f2;
  }
}.categery_gradient_border{
  background-image: url('/../../../../../../assets/img/Categories.png');
border-radius: 50%;
background-repeat: no-repeat;
background-position: center;
}
.categery_img{
background-color: var(--ion-color-white);
}
.show_on_facesheet{
  display: none;
}
.lab_result_popup{
  .show_on_facesheet{
    display: block;
  }
}

.toast-bottom-right {
  bottom: 2rem!important;
}

.medium-icon {
  font-size: 4rem;
}

.enabled-btn {
  --background: var(--ion-color-highlight); /* Blue for enabled */
  --color: white;
}
.disabled-btn {
  --background: var(--ion-color-gray60); /* Grey for disabled */
  --color: var(--white);
}
::ng-deep{
  .selected-dropdown-item{
    border-radius: 0.3rem;
    border: 0.1rem solid var(--ion-color-highlight);
    color:var(--ion-color-gray80) !important;
    background-color: var(--ion-color-highlightBG)!important;
    width: 100%;
  }
.k-list-item{
    padding: 1rem!important;
  }
}

.k-tooltip-wrapper .k-tooltip {
  box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12);
  background-color: rgba(39, 49, 56, 0.80) !important;
}
.k-callout-n{
  display: none !important;
}
.k-callout-w{
  display: none !important;
}
// For tooltip hover image
.tooltip_hover{
  cursor: url("../../assets/svg/mouse_cursor.png"), pointer;
}

//for tooltip
.addedtooltip {
  .k-popup.k-popup-transparent {
    border-radius: 1rem !important;
    margin-left: 13rem;
  }

  .k-tooltip {
    border-radius: 1rem;
    padding: 0 !important;
    max-width: 20rem;
    width: auto;
    word-wrap: break-word;
  }

  .k-animation-container {
    border-radius: 1rem;

  }
}

.tooltiplist {
  padding: 0.5rem 1rem;
  border-radius: 0.7rem !important;
  justify-content: space-between;
  line-height: 2rem!important;
  max-width: 80rem;
  width: auto;
  // background-color: #273138D9 !important;
  background-color: rgba(39, 49, 56, 0.2);

  cursor: pointer;

  .header2 {
    line-height: 2.3rem;
    letter-spacing: var(--q-letter-spacing-25);
  }
}


.separator{
  border-bottom: 0.1rem solid var(--ion-color-gray20);
}

.borderTop20{
  border-top:0.1rem solid #CCD4D8;
}
.disabled-btn {
  --ion-color-base: var(--ion-color-gray60); /* Grey for disabled */
}

::ng-deep{
  .ql-editor.ql-blank::before {
    color: var(--ion-color-gray40) !important;
    font-size: 1.4rem !important;
    font-weight: 300 !important;
  }
}

.borderBottom20{
  border-bottom: 1px solid var(--ion-color-gray20);
}

ion-app.IosDevice {
  ion-popover.sortByPopover {
    margin-top: -5rem;
  }
}
.white-space-normal{
  white-space: normal !important;
}

.pos-rel{
  position: relative;
}
.custom_tooltip{
  border-radius: 0.5rem ;
  padding: 0.5rem 1rem;
  box-shadow: none ;
  background-color: rgba(39, 49, 56, 0.8);
}

//For Drag and Drop
.custimize_btn {
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--ion-color-gray80);
  border-radius: 0.5rem;
}

.customize_popup {
  .customize_scroll {
    max-height: 80vh;
    width: 100%;
    margin-left: -0.1rem;
    height: 100%;

    @media (max-height:991px) {
      max-height: 65vh;
    }

    @media (max-width:575px) {
      max-height: 60vh;
    }
  }

  .custom_wrapper {
    background-color: var(--ion-color-gray05);

    .custom_inner {
      background-color: var(--white);
      border-radius: 1rem;
      border: 0.1rem solid var(--ion-color-gray20);

      .widget_card {
        border-bottom: 0.1rem solid var(--ion-color-gray20);


        .widget_icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:last-child {
          border-bottom: none;
        }
      }

    }
    .addition-widget{
      .widget_icon {
        ion-img{
          opacity: 0.5;
        }
      }
    }
  }
  .drag-icon{
    cursor: move;
  }
}

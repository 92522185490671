
.group {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  @include wordWrap;

  .group-label {
    @include header(6);
  }

}


//------------------------------------------------------------------------------
// DEFAULT FORMATTING VIA MODIFIER CLASSES

.group {

    //--------------------------------------------------------------------------
    // layout 1

    .groups-inline > &,
    &.group-inline {
      flex-flow: row;
      align-items: baseline;

      .group-field {

      }

      .group-label {
        margin-right: 7px;
      }
    }

    .groups.g3 > &,
    &.g3 {
      .group-field {
        @include header(3)
      }

      .group-label {

      }
    }


}


//------------------------------------------------------------------------------
// Table version

.groups-table {
  display: table;
  width: 100%;
  padding-left: 6px;

  .group {
    display: table-row;
    // border-bottom: $border--black--light;

    .group-label,
    .group-field {
      display: table-cell;
      padding: 3px;
    }
  }

}

    .groups-1 > .group,
    .group.group-1 {
      flex-flow: row;
      align-items: center;

      .group-field:not(input) {
        font-weight: bold;
      }

      .group-label {
        display: flex;
        justify-content: flex-end;
        font-weight: normal;
        text-transform: capitalize;
        font-size: inherit;
        padding-bottom: 0;
        text-align: right;
        // &:after {
        //   content: ':';
        //   margin: 0 .5rem 0 .1rem;
        //   align-self: center;
        // }
          flex-shrink: 0;
        }

    }


//------------------------------------------------------------------------------
// Indicate empty field with '--'

.group-indicateEmpty > .group-field:empty,
.groups-indicateEmpty > .group > .group-field:empty {
  min-height: 1rem;
  &:after {
    content: "--";
    letter-spacing: .1rem;
  }

}

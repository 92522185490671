//utilization colors
:root {
  --inpatient-color: #3F4b9b;
  --prof-billing-color: #428524;
  --physical-therapy-color: #995EB5;
  --outpatient-color: #008577;
  --skilled-nursing: #E61163;
  --home-health: #DD3900;
  --dme: #D3424D;
  --white: #fff;
}

@mixin border($color) {
  border: 0.2rem solid var($color);
  background-color: var($color);
  height: 0;
  margin: 0;
  width: 100%;
}

@mixin ordername($bg) {
  padding: 0 0.5rem;
  border-radius: 0.3rem;
  background-color: var($bg);
}


//color

.inpatient-color {
  color: var(--inpatient-color);
}

.prof-billing-color {
  color: var(--prof-billing-color);
}

.pt-color {
  color: var(--physical-therapy-color);
}
.outpatient-color {
  color: var(--outpatient-color);
}
.skilled-nursing-color {
  color: var(--skilled-nursing);
}
.home-health-color {
  color: var(--home-health);
}
.dme-color {
  color: var(--dme);
}

.white {
  color: var(--white) !important;

}

//border
.outpatient-border {
  @include border(--outpatient-color);
}

.skilled-nursing-border {
  @include border(--skilled-nursing);
}

.physical-therapy-border {
  @include border(--physical-therapy-color);
}

.inpatient-border {
  @include border(--inpatient-color);
}

.prof-billing-border {
  @include border(--prof-billing-color);
}

.home-health-border {
  @include border(--home-health);
}
.dme-border {
  @include border(--dme);
}

//order
.home-health-order {
  @include ordername(--home-health);
}
.skilled-nursing-order {
  @include ordername(--skilled-nursing);
}
.patient-therapy-order {
  @include ordername(--physical-therapy-color);
}
.inpatient-order {
  @include ordername(--inpatient-color);
}
.prof-billing-order {
  @include ordername(--prof-billing-color);
}
.outpatient-order {
  @include ordername(--outpatient-color);
}
.dme-order {
  @include ordername(--dme);
}

//add utilization icon color
@mixin utilization-icon($bg, $bc){
    height: 1.2rem;
    width: 1.2rem;
    background-color: var($bg);
    border: 0.2rem solid var($bc);
    border-radius: 0.2rem;
    display: inline-block;
    margin-right: 0.8rem;
}

.home-health-icon {
  @include utilization-icon(--home-health, --home-health);
}
.skilled-nursing-icon {
  @include utilization-icon(--skilled-nursing, --skilled-nursing);
}
.physical-therapy-icon {
  @include utilization-icon(--physical-therapy-color, --physical-therapy-color);
}
.inpatient-icon {
  @include utilization-icon(--inpatient-color, --inpatient-color);
}
.prof-billing-icon {
  @include utilization-icon(--prof-billing-color, --prof-billing-color);
}
.outpatient-icon {
  @include utilization-icon(--outpatient-color, --outpatient-color);
}
.dme-icon {
  @include utilization-icon(--dme, --dme);
}


.panel {
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
}

.panel-header {
  flex-shrink: 0;
  border-bottom: var(--q-app--border);
  h3 { margin: 0 6px; }
}
.panel-body {
  flex-grow: 1;
  overflow: auto;
}
.panel-footer {
  flex-shrink: 0;
}

.panel-header,
.panel-footer {
  padding: 6px calc(var(--ion-safe-area-right, 0) + 16px) 6px calc(var(--ion-safe-area-left, 0) + 16px);
}

.details-panel {
  // width: clamp(30rem, 25vw, 40rem);
  width: var(--width--details-panel);
  border-left: var(--q-app--border);
}

.details-panel-expands{
  width: var(--width--details-panel-expands);
  border-left: var(--q-app--border);
}

//typography
:root{
  --q-heading-fontsize: 1.5rem;
  --q-subtext-fontsize: 1.3rem;
}

.queue-heading{
  font-size: var(--q-heading-fontsize);
  font-weight: var(--q-font-weight-bold);
}

.queue-subtext{
  font-size: var(--q-subtext-fontsize);
  font-weight: var(--q-font-weight-regular);

}

.item-text {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 15px 0;
  row-gap: 3px;

  .text-row > * { margin: 0 }
}

.item-text-row {
  display: flex;
  align-items: baseline;
  column-gap: 3px;

  h1,h2,h3,h4,h5,h6,p { margin-top: 0; margin-bottom: 0 }



  &-label {
    @include header(6);
    margin-bottom: 5px;
  }

  &-primary {
    // margin-bottom: 5px;
  }

  &-secondary {
    font-size: var(--q-font-size--body2)
  }

  &-tertiary {
    font-size: var(--q-font-size--body2);
    // color: var(--ion-color-light);
    margin-top: 5px;
  }

  &-primary,
  &-secondary {
    & > *:first-child:not(.l-fill) + *:last-child {
      flex-grow: 1;
      text-align: right
    }
  }

}


// States

.is-unread {
  box-shadow: inset var(--ion-color-primary) .4rem 0 0 0;
  .item-text-row.item-text-row-primary {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
}


// Specially styled text within

// text below avatar that shows count "+2 others"
.avatar-text {
    font-size: 1.1rem;
    opacity: 0.6;
    font-style: italic;
}

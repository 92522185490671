  $config--gridRow--sizes: (
    xxxs: 2rem,
    xxs: 4rem,
    xs: 6rem,
    sm: 10rem,
    md: 15rem,
    lg: 20rem,
    xl: 25rem,
    xxl: 35rem,
    xxxl: 40rem,
  );

  %gridRow {
  --gridRow--column--width: #{map-get($config--gridRow--sizes, md)}; // Default
  --gridRow--column--sizing: auto-fill;
  --gap--column: 5px;
  --gap--row: 5px;

  display: grid;
  grid-template-columns: repeat(#{var(--gridRow--column--sizing)}, minmax(var(--gridRow--column--width), 1fr));
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  grid-row-gap: var(--gap--row);
  grid-column-gap: var(--gap--column);

}

.gridRow {
  @extend %gridRow;
}

.gridRow-fill {
  // Makes columns grow to fill space instead of filling with potentially empty columns
  --gridRow--column--sizing: auto-fit
}

@each $name, $definition in $config--gridRow--sizes {
    .gridRow-#{$name} {
      @extend %gridRow;
        --gridRow--column--width: #{$definition};

        // When we get to a certain screen size, set the column with to 100% so its never bigger than the screen
        @media (max-width: #{$definition}) {
          --gridRow--column--width: 100%;
        }

    }

}


.gridRow.gridRow-stack {
  @media screen and (max-width: 1100px) {
    // move to a column on smaller screens to avoid grid width issues
    --gridRow--column--width: 100%;
  }
}
.grid-dashboard {
  @media (max-width:767px) {
    grid-template-columns: auto ;
  }
}

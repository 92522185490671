@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin wordWrap($extent:"normal") {
  @include checkMixinForValidArguments($extent, all normal none);
  @if $extent == "all" {
    white-space: normal;
    word-break: break-all;
  } @else if $extent == "normal"  {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
  } @else if $extent == "none" {
    @include truncate;
  }
}

.u-truncateText {
  // Text doesnt wrap, gets ellipsis
  @include wordWrap(none); }

.u-truncateText--none {
  // Text wraps, no ellipsis
  @include wordWrap(normal); }

.u-wrapText {
  @include wordWrap(normal); }

.u-breakText--all {
  // Contains user generated tasks and may consist of long strings of letters without space, therefore needed word-break: break-all;
  @include wordWrap(all); }

.u-text-decoration--line-through {
  text-decoration: line-through;
}

@mixin badge($bg){
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 0.1rem solid #fff;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2rem;
  font-weight: 700;
  background-color: var($bg);
  color: #fff;
}

.badge-success{
  @include badge(--ion-color-success);
}

.badge-danger{
  @include badge(--ion-color-danger);
}

.badge-warning{
  @include badge(--ion-color-warning);
}

.badge-blue{
  @include badge(--q-color-primary);
}



.badges{
  position: absolute;
  z-index: 99;
  left: 2.9rem;
  top: 0.3rem;
  margin-top: 0rem;
  border: 0.1rem solid #fff;
  border-radius: 1rem;
  color: white;
  padding: 0.1rem 0.5rem;
  width: max-content;
  min-width: 2rem;
  height: 2rem;
  // line-height: 1.5rem !important;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* display: flex; */
  /* vertical-align: middle; */
  /* align-items: center; */

}

@mixin chip($bg){
  padding: 0.2rem 0.6rem;
  font-size: var(--q-body2-font-size);
  color: var(--ion-color-gray100);
  background-color: var($bg);
  border-radius: 10rem;
  display: inline-block;
  max-width: max-content;
}

.chip-lavendar{
  @include chip(--ion-color-acknowledge);
}
.chip-light-green{
  @include chip(--ion-color-appt-light-green);
}
.chip-teal{
  @include chip(--ion-color-teal);
}
.chip-red{
  @include chip(--ion-color-appt-red);
}
.chip-yellow{
  @include chip(--ion-color-yellow);
}
.chip-orange{
  @include chip(--ion-color-appt-orange);
}
.chip-green{
  @include chip(--ion-color-appt-green);
}
.chip-gray{
  @include chip(--ion-color-gray-bg);
}

.chip-dark-teal{
  @include chip(--ion-color-dark-teal);
}

.chip-dark-blue{
  @include chip(--ion-color-appt-dark-blue);
}

.chip-dark-gray{
  @include chip(--ion-color-gray100);
}
.chip-medium-gray{
  @include chip(--medical-category--gray);
}

.chip-dark-yellow{
  @include chip(--medical-category--yellow);
}
.chip-white{
  @include chip(--ion-color-white);
  border: 1px solid var(--ion-color-gray-bg);
}
.chip-light-orange{
  @include chip(--ion-color-orange-bg);
}
.chip-pink{
  @include chip(--ion-color-light-pink);
}
.chip-brown{
  @include chip(--medical-category--brown);
}
.chip-green-border{
  @include chip(--ion-color-white);
  border: 1px solid var(--ion-color-appt-green);
  color: var(--ion-color-appt-green);
}
.medicalAlert {
  &-1 {
    background: var(--medical-alert--1)
  }

  &-2 {
    background: var(--medical-alert--2)
  }

  &-3 {
    background: var(--medical-alert--3)
  }

  &-4 {
    background: var(--medical-alert--4)
  }
}

.medicalAlert {
  &-Lavendar {
    background: var(--medical-category--lavendar)
  }

  &-Violet {
    background: var(--medical-category--violet)
  }

  &-Dark-Blue {
    background: var(--medical-category--dark-blue)
  }

  &-Blue {
    background: var(--medical-category--blue)
  }

  &-Teal {
    background: var(--medical-category--teal)
  }

  &-Green {
    background: var(--medical-category--green)
  }

  &-Yellow {
    background: var(--medical-category--yellow)
  }

  &-Orange {
    background: var(--medical-category--orange)
  }

  &-Pink {
    background: var(--medical-category--pink)
  }

  &-Red {
    background: var(--medical-category--red)
  }

  &-Brown {
    background: var(--medical-category--brown)
  }

  &-Dark-Gray {
    background: var(--medical-category--dark-gray)
  }

  &-Gray {
    background: var(--medical-category--gray)
  }
}
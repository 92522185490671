.grid-dashboard {
  @extend %gridRow;

  --gap--column: 2rem;
  --gap--row: 2rem;
  --gridRow--column--width: 35rem;

  max-width: 1700px; // dashboard layouts may need a max width (ie - we dont want one big line of widgets on ultrawide
  padding: 2rem;

  ion-card {
    margin: 0;
  }

}

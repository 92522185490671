.medicalCategory-item {
  color: #000;
  background-color: rgba(255, 255, 255, .9);
  border: solid .5rem rgba(0, 0, 0, .05);
  padding: var(--q-app-space--xxs) var(--q-app-space--sm);
  border-radius: 2rem;
  font-size: var(--q-font-size--body2);
}

.medicalcat {
  &-Lavendar {
    border-color: var(--medical-category--lavendar)
  }

  &-Violet {
    border-color: var(--medical-category--violet)
  }

  &-Dark-Blue {
    border-color: var(--medical-category--dark-blue)
  }

  &-Blue {
    border-color: var(--medical-category--blue)
  }

  &-Teal {
    border-color: var(--medical-category--teal)
  }

  &-Green {
    border-color: var(--medical-category--green)
  }

  &-Yellow {
    border-color: var(--medical-category--yellow)
  }

  &-Orange {
    border-color: var(--medical-category--orange)
  }

  &-Pink {
    border-color: var(--medical-category--pink)
  }

  &-Red {
    border-color: var(--medical-category--red)
  }

  &-Brown {
    border-color: var(--medical-category--brown)
  }

  &-Dark-Gray {
    border-color: var(--medical-category--dark-gray)
  }

  &-Gray {
    border-color: var(--medical-category--gray)
  }
}